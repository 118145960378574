/* Note: This is an autogenerated file. Please DO NOT modify anything manually here. */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T> | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
  /**
   *
   *       A scalar type used to represent a page range for a template rule.
   *       Valid formats include: 1, "1", "1-", "1-2"
   *
   */
  PageRange: { input: any; output: any; }
  PositiveInt: { input: any; output: any; }
};

export type GQLAddCommentInput = {
  checklistItemId: Scalars['ID']['input'];
  comment: Scalars['String']['input'];
  documentId?: InputMaybe<Scalars['ID']['input']>;
  documentVersionId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQLAddDocumentInputInput = {
  documentId: Scalars['ID']['input'];
  input: GQLInputInput;
  pageId: Scalars['ID']['input'];
};

export type GQLAddDocumentsForUploadToInteractionInput = {
  documents: Array<GQLDocumentForUpload>;
};

export type GQLAddDocumentsToInteractionInput = {
  /**
   * A list of Document Template IDs to add.
   * Optional if the `propertyOptionGroups` parameter is included.
   */
  documents?: InputMaybe<Array<Scalars['ID']['input']>>;
  /**
   * A list of Property Option Group IDs to add.
   * Optional if the `documents` parameter is included.
   */
  propertyOptionGroups?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** An optional list of User Template IDs to add. */
  userTemplates?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GQLAddIdentityTeamFollowUpBossExternalServiceInput = {
  adminEmail?: InputMaybe<Scalars['String']['input']>;
  apiKey?: InputMaybe<Scalars['String']['input']>;
  luxuryPresenceApiKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pixelWidgetTrackerCode?: InputMaybe<Scalars['String']['input']>;
  supportTier?: InputMaybe<Scalars['Int']['input']>;
};

export type GQLAddIdentityTeamMemberContactInput = {
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street1?: InputMaybe<Scalars['String']['input']>;
  street2?: InputMaybe<Scalars['String']['input']>;
  titleNumber?: InputMaybe<Scalars['String']['input']>;
  type: GQLIdentityTeamMemberContactType;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type GQLAddImagesForUploadInput = {
  images: Array<GQLImageForUpload>;
};

export type GQLAddNoteInput = {
  content: Scalars['String']['input'];
  documentId?: InputMaybe<Scalars['ID']['input']>;
  documentVersionId?: InputMaybe<Scalars['ID']['input']>;
  itemId?: InputMaybe<Scalars['ID']['input']>;
};

export enum GQLAddableType {
  inline = 'inline',
  separate = 'separate'
}

export type GQLAdjustmentMutationInput = {
  id: Scalars['ID']['input'];
};

export type GQLAdminTransactionQueryInput = {
  id: Scalars['ID']['input'];
};

export enum GQLAppFeature {
  COMMUNITY_LISTINGS = 'COMMUNITY_LISTINGS',
  /** @deprecated no longer used */
  CREATE_REFERRAL = 'CREATE_REFERRAL',
  LEASES = 'LEASES',
  /** @deprecated no longer used */
  REFERRALS = 'REFERRALS',
  REVERT_DRAFT_ENVELOPES = 'REVERT_DRAFT_ENVELOPES'
}

export type GQLApproveCompanyInput = {
  company: GQLApproveCompanyStatusInput;
  contact: GQLSettlementContactV2UpdateInput;
};

export type GQLApproveCompanyStatusInput = {
  status?: InputMaybe<GQLSettlementCompanyStatus>;
};

export type GQLApproveDocumentInput = {
  documentId: Scalars['ID']['input'];
};

export type GQLAssignBrokersInput = {
  brokers: Array<GQLAssignedBrokerInfoInput>;
  groupEmail?: InputMaybe<Scalars['String']['input']>;
  reason: Scalars['String']['input'];
};

export type GQLAssignedBrokerInfoInput = {
  email: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type GQLAttachmentInput = {
  name: Scalars['String']['input'];
  size: Scalars['Int']['input'];
  type: Scalars['String']['input'];
};

export type GQLAttentionItemPaginationInput = {
  page: Scalars['Int']['input'];
  perPage: Scalars['Int']['input'];
};

export type GQLAuditByInteractionIdInput = {
  interactionId: Scalars['ID']['input'];
  interactionType?: InputMaybe<GQLInteractionType>;
};

export enum GQLAuditDocumentReviewStatus {
  CORRECT = 'CORRECT',
  INCORRECT = 'INCORRECT'
}

export enum GQLAuditDocumentStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export type GQLAuditDocumentsFilter = {
  checklistItemId?: InputMaybe<Scalars['String']['input']>;
  isAssigned?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum GQLAuditHistoryAction {
  AGENT_CHANGED = 'AGENT_CHANGED',
  AUDIT_ASSIGNED = 'AUDIT_ASSIGNED',
  CHECKLIST_DOCUMENTS_DOWNLOADED = 'CHECKLIST_DOCUMENTS_DOWNLOADED',
  CHECKLIST_ITEM_ADDED = 'CHECKLIST_ITEM_ADDED',
  CHECKLIST_ITEM_COMMENT = 'CHECKLIST_ITEM_COMMENT',
  CHECKLIST_ITEM_COMMENT_EDITED = 'CHECKLIST_ITEM_COMMENT_EDITED',
  CHECKLIST_ITEM_DELETED = 'CHECKLIST_ITEM_DELETED',
  CHECKLIST_ITEM_REQUIRED = 'CHECKLIST_ITEM_REQUIRED',
  CHECKLIST_ITEM_UNREQUIRED = 'CHECKLIST_ITEM_UNREQUIRED',
  CHECKLIST_TEMPLATE_CHANGED = 'CHECKLIST_TEMPLATE_CHANGED',
  CLOSING_DATE_CHANGED = 'CLOSING_DATE_CHANGED',
  COMMISSION_CHANGED = 'COMMISSION_CHANGED',
  COMMUNICATION_RECEIVED = 'COMMUNICATION_RECEIVED',
  COMMUNICATION_SENT = 'COMMUNICATION_SENT',
  CONTACTS_CHANGED = 'CONTACTS_CHANGED',
  COVERSHEET_UPDATED = 'COVERSHEET_UPDATED',
  DOCUMENT_ADDED = 'DOCUMENT_ADDED',
  DOCUMENT_APPROVED = 'DOCUMENT_APPROVED',
  DOCUMENT_ARCHIVED = 'DOCUMENT_ARCHIVED',
  DOCUMENT_ASSIGNED = 'DOCUMENT_ASSIGNED',
  DOCUMENT_COMMENTED = 'DOCUMENT_COMMENTED',
  DOCUMENT_COMMENT_EDITED = 'DOCUMENT_COMMENT_EDITED',
  DOCUMENT_DOWNLOADED = 'DOCUMENT_DOWNLOADED',
  DOCUMENT_REJECTED = 'DOCUMENT_REJECTED',
  DOCUMENT_SIGNATURE_REQUESTED = 'DOCUMENT_SIGNATURE_REQUESTED',
  DOCUMENT_UNASSIGNED = 'DOCUMENT_UNASSIGNED',
  DOCUMENT_VALIDATED = 'DOCUMENT_VALIDATED',
  DOCUMENT_VERSION_UPDATED = 'DOCUMENT_VERSION_UPDATED',
  DOCUMENT_VIEWED = 'DOCUMENT_VIEWED',
  EXPIRED_DATE_CHANGED = 'EXPIRED_DATE_CHANGED',
  HISTORICAL_DISCLOSURES_FOUND = 'HISTORICAL_DISCLOSURES_FOUND',
  HISTORICAL_DISCLOSURES_SENT = 'HISTORICAL_DISCLOSURES_SENT',
  LISTING_OPENED = 'LISTING_OPENED',
  NEW_LISTING = 'NEW_LISTING',
  NEW_TRANSACTION = 'NEW_TRANSACTION',
  NHD_REPORT_REQUESTED = 'NHD_REPORT_REQUESTED',
  NOTE_ADDED = 'NOTE_ADDED',
  NOTE_EDITED = 'NOTE_EDITED',
  REACTIVATED = 'REACTIVATED',
  REACTIVATION_REQUESTED = 'REACTIVATION_REQUESTED',
  REVERTED_TO_LISTING = 'REVERTED_TO_LISTING',
  STAGE_CHANGED = 'STAGE_CHANGED',
  STATUS_CHANGED = 'STATUS_CHANGED',
  SUBMISSION_BLOCKED = 'SUBMISSION_BLOCKED',
  TRANSACTION_OPENED = 'TRANSACTION_OPENED',
  TRANSFER_OWNER = 'TRANSFER_OWNER'
}

export enum GQLAuditHistoryActionType {
  CHECKLIST_ITEM = 'CHECKLIST_ITEM',
  DOCUMENT = 'DOCUMENT',
  EMAIL_RECEIVED = 'EMAIL_RECEIVED',
  EMAIL_SENT = 'EMAIL_SENT',
  LISTING = 'LISTING',
  TRANSACTION = 'TRANSACTION'
}

export type GQLAuditHistoryDownloadParams = {
  filter?: InputMaybe<GQLAuditHistoryFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortModel?: InputMaybe<Array<GQLDatagridSortModel>>;
};

export type GQLAuditHistoryFilter = {
  action?: InputMaybe<Array<GQLAuditHistoryAction>>;
  actionType?: InputMaybe<Array<GQLAuditHistoryActionType>>;
  createdAtFinish?: InputMaybe<Scalars['String']['input']>;
  createdAtStart?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQLAuditHistoryParams = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<GQLAuditHistoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortModel?: InputMaybe<Array<GQLDatagridSortModel>>;
};

export enum GQLAuditHistoryReference {
  AUDIT = 'AUDIT',
  AUDIT_CHECKLIST_CATEGORY = 'AUDIT_CHECKLIST_CATEGORY',
  AUDIT_CHECKLIST_ITEM = 'AUDIT_CHECKLIST_ITEM',
  CONTACT = 'CONTACT',
  DOCUMENT = 'DOCUMENT',
  DOCUMENT_VERSION = 'DOCUMENT_VERSION',
  NOTE = 'NOTE'
}

export enum GQLAuditInteractionType {
  LEASE = 'LEASE',
  LISTING = 'LISTING',
  OFFER = 'OFFER'
}

export enum GQLAuditJobStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  SUCCEEDED = 'SUCCEEDED'
}

export type GQLAuditNotesFilter = {
  checklistItemId?: InputMaybe<Scalars['String']['input']>;
  documentId?: InputMaybe<Scalars['String']['input']>;
  documentVersionId?: InputMaybe<Scalars['String']['input']>;
  isAssigned?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum GQLAuditStage {
  BROKER_ACCEPTED = 'BROKER_ACCEPTED',
  BROKER_APPROVAL_TO_PAY = 'BROKER_APPROVAL_TO_PAY',
  BROKER_REVIEW = 'BROKER_REVIEW',
  CLOSING_HOLD = 'CLOSING_HOLD',
  COMPLIANCE_HOLD = 'COMPLIANCE_HOLD',
  FINAL_REVIEW = 'FINAL_REVIEW',
  IN_REVIEW = 'IN_REVIEW',
  LEASE_REVIEW_COMPLETED = 'LEASE_REVIEW_COMPLETED',
  LISTING_INITIAL_REVIEW = 'LISTING_INITIAL_REVIEW',
  LISTING_REVIEWED = 'LISTING_REVIEWED',
  OFFER_INITIAL_REVIEW = 'OFFER_INITIAL_REVIEW',
  READY_FOR_DRIP_CAMPAIGN = 'READY_FOR_DRIP_CAMPAIGN',
  SECOND_REVIEW = 'SECOND_REVIEW'
}

export enum GQLAuditStatus {
  ACTIVE_LISTING = 'ACTIVE_LISTING',
  ARCHIVED = 'ARCHIVED',
  CANCELATION_REQUESTED = 'CANCELATION_REQUESTED',
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  REACTIVATION_REQUESTED = 'REACTIVATION_REQUESTED',
  TRANSACTION_CANCELATION_REQUESTED = 'TRANSACTION_CANCELATION_REQUESTED',
  TRANSFER = 'TRANSFER'
}

export type GQLAuditsListFilter = {
  acceptanceDateFinish?: InputMaybe<Scalars['String']['input']>;
  acceptanceDateStart?: InputMaybe<Scalars['String']['input']>;
  client?: InputMaybe<Array<Scalars['String']['input']>>;
  closingDateFinish?: InputMaybe<Scalars['String']['input']>;
  closingDateStart?: InputMaybe<Scalars['String']['input']>;
  createdAtFinish?: InputMaybe<Scalars['String']['input']>;
  createdAtStart?: InputMaybe<Scalars['String']['input']>;
  hasPendingDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  hasRejectedDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  hasRequiredItems?: InputMaybe<Scalars['Boolean']['input']>;
  hideEscalated?: InputMaybe<Scalars['Boolean']['input']>;
  interactionType?: InputMaybe<GQLAuditInteractionType>;
  isClosingEmailProgramParticipant?: InputMaybe<Scalars['Boolean']['input']>;
  isExpired?: InputMaybe<Scalars['Boolean']['input']>;
  isSoonToBeExpired?: InputMaybe<Scalars['Boolean']['input']>;
  listingEndDateFinish?: InputMaybe<Scalars['String']['input']>;
  listingEndDateStart?: InputMaybe<Scalars['String']['input']>;
  needsAttention?: InputMaybe<Scalars['Boolean']['input']>;
  primaryAgent?: InputMaybe<Array<Scalars['String']['input']>>;
  reviewPriority?: InputMaybe<GQLReviewPriority>;
  showEscalated?: InputMaybe<Scalars['Boolean']['input']>;
  stage?: InputMaybe<Array<GQLAuditStage>>;
  state?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<GQLAuditStatus>>;
  team?: InputMaybe<Array<Scalars['String']['input']>>;
  transactionCoordinator?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedAtFinish?: InputMaybe<Scalars['String']['input']>;
  updatedAtStart?: InputMaybe<Scalars['String']['input']>;
};

export type GQLAuditsListParams = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<GQLAuditsListFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSessionDuration?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortModel?: InputMaybe<Array<GQLDatagridSortModel>>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type GQLBatchCursorPaginationInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<InputMaybe<GQLFacetFilter>>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<GQLDatagridSortModel>>>;
};

export type GQLBrokerageInput = {
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  jurisdiction?: InputMaybe<Scalars['String']['input']>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  mlsCode?: InputMaybe<Scalars['String']['input']>;
  mlsId?: InputMaybe<Scalars['String']['input']>;
  officeEmail?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street1?: InputMaybe<Scalars['String']['input']>;
  street2?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum GQLBuyerRelationshipType {
  EXCLUSIVE = 'EXCLUSIVE',
  NON_EXCLUSIVE = 'NON_EXCLUSIVE'
}

export type GQLCancelAuditInput = {
  reason: Scalars['String']['input'];
  transaction?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQLCertifyTransactionInput = {
  lastModifiedAt: Scalars['String']['input'];
};

export type GQLChangeCompanyNameInput = {
  company: GQLChangeCompanyNameStatusInput;
  contact: GQLSettlementContactV2UpdateInput;
};

export type GQLChangeCompanyNameStatusInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<GQLSettlementCompanyStatus>;
};

export enum GQLChecklistItemDerivedStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export enum GQLChecklistItemDocumentType {
  BUYER_AGREEMENT = 'BUYER_AGREEMENT',
  LEGAL_DESCRIPTION = 'LEGAL_DESCRIPTION',
  LISTING_AGREEMENT = 'LISTING_AGREEMENT',
  PRELIM_TITLE = 'PRELIM_TITLE',
  PURCHASE_CONTRACT = 'PURCHASE_CONTRACT',
  TAX_RECORD = 'TAX_RECORD'
}

export type GQLChecklistItemTemplateListV2Params = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<GQLItemTemplateListFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortModel?: InputMaybe<Array<GQLDatagridSortModel>>;
};

export type GQLChecklistTemplateListDraftsV2Params = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortModel?: InputMaybe<Array<GQLDatagridSortModel>>;
};

export type GQLChecklistTemplateListFilter = {
  interactionType?: InputMaybe<GQLInteractionType>;
  localeId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQLChecklistTemplateListParams = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<GQLChecklistTemplateListFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortModel?: InputMaybe<Array<GQLDatagridSortModel>>;
};

export type GQLChecklistTemplateListV2Filter = {
  interactionType?: InputMaybe<GQLInteractionType>;
  locale?: InputMaybe<Scalars['String']['input']>;
  propertyType?: InputMaybe<GQLPropertyType>;
  status?: InputMaybe<Array<GQLVersionStatus>>;
};

export type GQLChecklistTemplateListV2Params = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<GQLChecklistTemplateListV2Filter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortModel?: InputMaybe<Array<GQLDatagridSortModel>>;
};

export type GQLChecklistTemplateOptionsInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum GQLChecklistType {
  CANCELATION = 'CANCELATION',
  MAIN = 'MAIN',
  REACTIVATION = 'REACTIVATION'
}

export enum GQLChecklistVersion {
  CHECKLIST_V1 = 'CHECKLIST_V1',
  CHECKLIST_V2 = 'CHECKLIST_V2'
}

export enum GQLClientGroupAgreementStatus {
  completed = 'completed',
  created = 'created',
  declined = 'declined',
  delivered = 'delivered',
  failed = 'failed',
  notFinalized = 'notFinalized',
  notSent = 'notSent',
  partiallySigned = 'partiallySigned',
  sending = 'sending',
  sent = 'sent',
  voided = 'voided'
}

export type GQLCloneInteractionInput = {
  leaseRepresentation?: InputMaybe<GQLLeaseRepresentation>;
  location: GQLInteractionLocationInput;
  mlsNumber?: InputMaybe<Scalars['String']['input']>;
  mlsServiceId?: InputMaybe<Scalars['String']['input']>;
  sourceId: Scalars['ID']['input'];
  status?: InputMaybe<GQLInteractionStatus>;
};

export type GQLCoListCreateInput = {
  amount: Scalars['Float']['input'];
  teamMemberId: Scalars['ID']['input'];
};

export type GQLCoListMutationInput = {
  cancelId?: InputMaybe<Scalars['ID']['input']>;
  create?: InputMaybe<GQLCoListCreateInput>;
  parent: GQLCoListParentInput;
  update?: InputMaybe<GQLCoListUpdateInput>;
};

export type GQLCoListParentInput = {
  amount: Scalars['Float']['input'];
};

export type GQLCoListUpdateInput = {
  amount: Scalars['Float']['input'];
  id: Scalars['ID']['input'];
};

export type GQLCommissionNotificationsInput = {
  commissionId: Scalars['ID']['input'];
  page: Scalars['Int']['input'];
  perPage: Scalars['Int']['input'];
  sort?: InputMaybe<Array<GQLDatagridSortModel>>;
};

/** Commission Models */
export enum GQLCommissionPartyType {
  BROKERAGE = 'BROKERAGE',
  EXTERNAL_AGENT = 'EXTERNAL_AGENT',
  EXTERNAL_BROKERAGE = 'EXTERNAL_BROKERAGE',
  EXTERNAL_COMPANY = 'EXTERNAL_COMPANY',
  EXTERNAL_PERSON = 'EXTERNAL_PERSON',
  OUTSIDE_BROKERAGE = 'OUTSIDE_BROKERAGE',
  PROFILE = 'PROFILE',
  REAL_ESTATE_SERVICES = 'REAL_ESTATE_SERVICES',
  REFERRAL_COMPANY = 'REFERRAL_COMPANY',
  SETTLEMENT_COMPANY = 'SETTLEMENT_COMPANY',
  SETTLEMENT_CONTACT = 'SETTLEMENT_CONTACT',
  TEAM = 'TEAM',
  TEAM_MEMBER = 'TEAM_MEMBER'
}

export enum GQLCommissionStatus {
  ADJUSTMENT_COMPUTED = 'ADJUSTMENT_COMPUTED',
  ADJUSTMENT_SUBMITTED = 'ADJUSTMENT_SUBMITTED',
  COMPUTED = 'COMPUTED',
  CREATED = 'CREATED',
  DRAFT = 'DRAFT',
  FINAL_COMPUTED = 'FINAL_COMPUTED',
  FINAL_LOCKED = 'FINAL_LOCKED',
  FINAL_STATEMENT_GENERATED = 'FINAL_STATEMENT_GENERATED',
  FINAL_SUBMITTED = 'FINAL_SUBMITTED',
  LOCKED = 'LOCKED',
  NEW = 'NEW',
  STATEMENT_GENERATED = 'STATEMENT_GENERATED',
  SUBMITTED = 'SUBMITTED',
  TAXES_GENERATED = 'TAXES_GENERATED'
}

export enum GQLCommissionSystem {
  BROKERMINT = 'BROKERMINT',
  SAP = 'SAP',
  SIDE = 'SIDE',
  UNKNOWN = 'UNKNOWN'
}

export enum GQLCommissionType {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
  PERCENTAGE_ALL_COMMISSIONS = 'PERCENTAGE_ALL_COMMISSIONS',
  PERCENTAGE_LISTING_COMMISSION = 'PERCENTAGE_LISTING_COMMISSION',
  PERCENTAGE_PURCHASE_COMMISSION = 'PERCENTAGE_PURCHASE_COMMISSION'
}

export type GQLCommunityListingsQueryInput = {
  searchCenter?: InputMaybe<GQLCommunityListingsSearchCenterInput>;
  searchRadius?: InputMaybe<Scalars['Int']['input']>;
};

export type GQLCommunityListingsSearchCenterInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export enum GQLContactDisplayType {
  BUYER = 'BUYER',
  BUYERS_AGENT = 'BUYERS_AGENT',
  BUYERS_ATTORNEY = 'BUYERS_ATTORNEY',
  BUYERS_TC = 'BUYERS_TC',
  CLOSING_ATTORNEY = 'CLOSING_ATTORNEY',
  ESCROW_OFFICER = 'ESCROW_OFFICER',
  SELLER = 'SELLER',
  SELLERS_AGENT = 'SELLERS_AGENT',
  SELLERS_ATTORNEY = 'SELLERS_ATTORNEY',
  SELLERS_TC = 'SELLERS_TC',
  TITLE_OFFICER = 'TITLE_OFFICER'
}

export enum GQLContactEntityType {
  BROKERAGE = 'BROKERAGE',
  ENTITY = 'ENTITY',
  INDIVIDUAL = 'INDIVIDUAL',
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
  PROBATE = 'PROBATE',
  TRUST = 'TRUST'
}

export type GQLContactInput = {
  addressTypeUsed?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  broker?: InputMaybe<GQLInteractionContactGeneralInput>;
  brokerage?: InputMaybe<GQLBrokerageInput>;
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  commission?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  contactSource?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  entity?: InputMaybe<GQLOwnershipInput>;
  entityType?: InputMaybe<Scalars['String']['input']>;
  etag?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  googleContactId?: InputMaybe<Scalars['String']['input']>;
  homePhone?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isSelfRepresented?: InputMaybe<Scalars['Boolean']['input']>;
  isSuccessor?: InputMaybe<Scalars['Boolean']['input']>;
  jurisdiction?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  listingPeriod?: InputMaybe<Scalars['String']['input']>;
  mlsId?: InputMaybe<Scalars['String']['input']>;
  officeManager?: InputMaybe<GQLInteractionContactGeneralInput>;
  officeNumber?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  powerOfAttorney?: InputMaybe<GQLInteractionContactGeneralInput>;
  primaryMls?: InputMaybe<Scalars['String']['input']>;
  probate?: InputMaybe<GQLOwnershipInput>;
  role?: InputMaybe<Scalars['String']['input']>;
  sellingCommission?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street1?: InputMaybe<Scalars['String']['input']>;
  street2?: InputMaybe<Scalars['String']['input']>;
  supervisingBroker?: InputMaybe<GQLInteractionContactGeneralInput>;
  teamBusinessLicenseEntityNumber?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  teamName?: InputMaybe<Scalars['String']['input']>;
  trust?: InputMaybe<GQLOwnershipInput>;
  type?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type GQLContactSuggestionInput = {
  excludeNonCompliantTeams?: InputMaybe<Scalars['Boolean']['input']>;
  includeReferralAgents?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchValue: Scalars['String']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export enum GQLContactType {
  AGENT = 'AGENT',
  ATTORNEY = 'ATTORNEY',
  CLIENT = 'CLIENT',
  CLOSING_ATTORNEY = 'CLOSING_ATTORNEY',
  COUNTERPARTY_AGENT = 'COUNTERPARTY_AGENT',
  COUNTERPARTY_ATTORNEY = 'COUNTERPARTY_ATTORNEY',
  COUNTERPARTY_CLIENT = 'COUNTERPARTY_CLIENT',
  COUNTERPARTY_TRANSACTION_COORDINATOR = 'COUNTERPARTY_TRANSACTION_COORDINATOR',
  ESCROW_OFFICER = 'ESCROW_OFFICER',
  TITLE_OFFICER = 'TITLE_OFFICER',
  TRANSACTION_COORDINATOR = 'TRANSACTION_COORDINATOR'
}

export enum GQLContentDisposition {
  attachment = 'attachment',
  inline = 'inline'
}

export type GQLCoverSheetConfigQueryInput = {
  interactionId: Scalars['ID']['input'];
  interactionType: GQLInteractionType;
  representationType?: InputMaybe<GQLLeaseRepresentation>;
};

export type GQLCreateAttachmentsInput = {
  attachments: Array<GQLAttachmentInput>;
};

export enum GQLCreateAuditHistoryAction {
  COMMUNICATION_RECEIVED = 'COMMUNICATION_RECEIVED',
  DOCUMENT_DOWNLOADED = 'DOCUMENT_DOWNLOADED',
  DOCUMENT_VIEWED = 'DOCUMENT_VIEWED',
  LISTING_OPENED = 'LISTING_OPENED',
  SUBMISSION_BLOCKED = 'SUBMISSION_BLOCKED',
  TRANSACTION_OPENED = 'TRANSACTION_OPENED'
}

export type GQLCreateAuditHistoryEvent = {
  comment?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  referenceId: Scalars['ID']['input'];
  referenceType: GQLAuditHistoryReference;
};

export type GQLCreateAuditHistoryInput = {
  action: GQLCreateAuditHistoryAction;
  actionType: GQLAuditHistoryActionType;
  createdByEmail?: InputMaybe<Scalars['String']['input']>;
  event?: InputMaybe<GQLCreateAuditHistoryEvent>;
};

export type GQLCreateAuditInput = {
  interactionId: Scalars['ID']['input'];
  interactionType: GQLAuditInteractionType;
};

export type GQLCreateAuditSessionInput = {
  endTime: Scalars['DateTime']['input'];
  startTime: Scalars['DateTime']['input'];
};

export type GQLCreateChecklistItemInput = {
  categoryId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  isRequired: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type GQLCreateChecklistItemTemplateV2Input = {
  acceptWhenAutoAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documentType?: InputMaybe<GQLChecklistItemDocumentType>;
  locale: Scalars['String']['input'];
  name: Scalars['String']['input'];
  riskLevel?: InputMaybe<GQLRiskLevel>;
  status?: InputMaybe<GQLVersionStatus>;
  transactionPhase: GQLTransactionPhase;
};

export type GQLCreateChecklistItemTemplateVersionV2Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  riskLevel?: InputMaybe<GQLRiskLevel>;
};

export type GQLCreateChecklistTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  interactionType: GQLInteractionType;
  items?: InputMaybe<Array<GQLTemplateItemsInput>>;
  localeId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  propertyType?: InputMaybe<GQLPropertyType>;
  type: GQLChecklistType;
};

export type GQLCreateChecklistTemplateV2Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  interactionType: GQLInteractionType;
  locale?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  propertyType: GQLPropertyType;
};

export type GQLCreateChecklistTemplateVersionV2Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQLCreateCoverSheetEntityInput = {
  entity: Scalars['String']['input'];
  entityType?: InputMaybe<Scalars['String']['input']>;
  fields: Array<GQLUpdateCoverSheetFieldData>;
  interactionId: Scalars['String']['input'];
  interactionType: GQLInteractionType;
};

export type GQLCreateDelegateGroupInput = {
  delegatedToId: Array<Scalars['ID']['input']>;
  onBehalfOfId: Scalars['ID']['input'];
  permissions: Array<Scalars['String']['input']>;
};

export type GQLCreateDocTemplateInput = {
  appearsIn: Array<GQLDocTemplateAppearsIn>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  formLibrary?: InputMaybe<Scalars['String']['input']>;
  formName?: InputMaybe<Scalars['String']['input']>;
  formVersion?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isCustom?: InputMaybe<Scalars['Boolean']['input']>;
  isGlobal?: InputMaybe<Scalars['Boolean']['input']>;
  isReadyForReporting?: InputMaybe<Scalars['Boolean']['input']>;
  listingOrder?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  offerOrder?: InputMaybe<Scalars['Int']['input']>;
  revisionMonth?: InputMaybe<Scalars['Int']['input']>;
  revisionYear?: InputMaybe<Scalars['Int']['input']>;
};

export type GQLCreateDocTemplateInputInput = {
  applyCommaSeparator?: InputMaybe<Scalars['Boolean']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  coversheetValue?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  dropdownOptions?: InputMaybe<Scalars['String']['input']>;
  dropdownPlaceholder?: InputMaybe<Scalars['String']['input']>;
  fontSize?: InputMaybe<Scalars['Float']['input']>;
  formation?: InputMaybe<GQLInitialsGroupFormation>;
  formula?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Array<GQLDocTemplateInputGroupInput>>;
  height?: InputMaybe<Scalars['Float']['input']>;
  image?: InputMaybe<GQLDocTemplatePageInputImageType>;
  initialsGroup?: InputMaybe<Array<GQLInitialsGroupItemInput>>;
  injectOnly?: InputMaybe<Scalars['Boolean']['input']>;
  isInjectedMultiline?: InputMaybe<Scalars['Boolean']['input']>;
  left?: InputMaybe<Scalars['Float']['input']>;
  maxLength?: InputMaybe<Scalars['String']['input']>;
  multiline?: InputMaybe<GQLDocTemplateInputGroupInput>;
  multilineValue?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  optional?: InputMaybe<Scalars['Boolean']['input']>;
  optionalSide?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<GQLCreateDocTemplateInputOptionInput>>;
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  recipientId?: InputMaybe<Scalars['String']['input']>;
  reportingName?: InputMaybe<Scalars['String']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  scale?: InputMaybe<Scalars['Float']['input']>;
  suffix?: InputMaybe<Scalars['String']['input']>;
  tabIndex?: InputMaybe<Scalars['Int']['input']>;
  targetFieldNames?: InputMaybe<Scalars['JSONObject']['input']>;
  textAlign?: InputMaybe<Scalars['String']['input']>;
  top?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<GQLInputType>;
  typeFace?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type GQLCreateDocTemplateInputOptionInput = {
  left?: InputMaybe<Scalars['Float']['input']>;
  reportingName?: InputMaybe<Scalars['String']['input']>;
  tabIndex?: InputMaybe<Scalars['Int']['input']>;
  top?: InputMaybe<Scalars['Float']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type GQLCreateDocumentInput = {
  checklistItemId?: InputMaybe<Scalars['ID']['input']>;
  documentId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQLCreateIdentityBrokerageInput = {
  address: GQLCreateIdentityUserAddressInput;
  brokerEmail: Scalars['String']['input'];
  brokerLicenseId: Scalars['ID']['input'];
  brokerPhone: Scalars['String']['input'];
  brokerUserId: Scalars['ID']['input'];
  licenseExpiration: Scalars['String']['input'];
  licenseNumber: Scalars['String']['input'];
  market: GQLIdentitySupportedStateCode;
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type GQLCreateIdentityLicenseInput = {
  expiration: Scalars['String']['input'];
  number: Scalars['String']['input'];
  state: GQLIdentitySupportedStateCode;
};

export type GQLCreateIdentityTeamAddressInput = {
  city: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: GQLIdentityStateCode;
  street1: Scalars['String']['input'];
  street2?: InputMaybe<Scalars['String']['input']>;
};

export type GQLCreateIdentityTeamInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  brokerEmailId: Scalars['ID']['input'];
  brokerLicenseId: Scalars['ID']['input'];
  brokerPhoneId: Scalars['ID']['input'];
  brokerUserId: Scalars['ID']['input'];
  brokerageId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  legalName: Scalars['String']['input'];
  logoHorizontalUri?: InputMaybe<Scalars['String']['input']>;
  logoHorizontalUriAlt?: InputMaybe<Scalars['String']['input']>;
  logoUri?: InputMaybe<Scalars['String']['input']>;
  logoUriAlt?: InputMaybe<Scalars['String']['input']>;
  mailingAddress?: InputMaybe<GQLCreateIdentityTeamAddressInput>;
  mlsId?: InputMaybe<Scalars['String']['input']>;
  mlsOfficeEmail?: InputMaybe<Scalars['String']['input']>;
  parentTeamId?: InputMaybe<Scalars['ID']['input']>;
  phoneNumber: Scalars['String']['input'];
  registeredAddress?: InputMaybe<GQLCreateIdentityTeamAddressInput>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type GQLCreateIdentityTeamMemberInput = {
  email: Scalars['String']['input'];
  externalId: Scalars['ID']['input'];
  licenseId?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  roles?: InputMaybe<Array<GQLIdentityTeamMemberRoleCode>>;
  userId: Scalars['ID']['input'];
};

export type GQLCreateIdentityUserAddressInput = {
  city: Scalars['String']['input'];
  country?: InputMaybe<GQLIdentitySupportedCountryCode>;
  county?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  state: GQLIdentityStateCode;
  street1: Scalars['String']['input'];
  street2?: InputMaybe<Scalars['String']['input']>;
};

export type GQLCreateIdentityUserContactInput = {
  isPersonal?: InputMaybe<Scalars['Boolean']['input']>;
  isPrimary: Scalars['Boolean']['input'];
  type: GQLIdentityContactType;
  value: Scalars['String']['input'];
};

export type GQLCreateInteractionContactInput = {
  fields: Array<GQLUpdateCoverSheetFieldData>;
  type: GQLInteractionContactType;
};

export type GQLCreateInteractionInput = {
  clientGroupId?: InputMaybe<Scalars['ID']['input']>;
  contacts?: InputMaybe<Array<GQLCreateInteractionContactInput>>;
  leaseRepresentation?: InputMaybe<GQLLeaseRepresentation>;
  location?: InputMaybe<GQLInteractionLocationInput>;
  mlsNumber?: InputMaybe<Scalars['String']['input']>;
  mlsServiceId?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['ID']['input']>;
  referralId?: InputMaybe<Scalars['ID']['input']>;
  referralType?: InputMaybe<GQLReferralType>;
  status?: InputMaybe<GQLInteractionStatus>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQLCreateListingPriceHistoryInput = {
  price?: InputMaybe<Scalars['Float']['input']>;
};

export type GQLCreateLocaleInput = {
  cityName?: InputMaybe<Scalars['String']['input']>;
  countyName?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  stateName?: InputMaybe<Scalars['String']['input']>;
  type: GQLLocaleType;
};

export type GQLCreateMlsMutationInput = {
  displayName: Scalars['String']['input'];
  hintText?: InputMaybe<Scalars['String']['input']>;
  locales: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  vendor: Scalars['String']['input'];
};

export type GQLCreateOfferOnListingInput = {
  closingDate?: InputMaybe<Scalars['String']['input']>;
  contacts?: InputMaybe<Array<InputMaybe<GQLContactInput>>>;
  contingencies?: InputMaybe<Array<InputMaybe<GQLOfferContingencyInput>>>;
  downPaymentAmount?: InputMaybe<Scalars['Float']['input']>;
  downPaymentPercentage?: InputMaybe<Scalars['Float']['input']>;
  emdAmount?: InputMaybe<Scalars['Float']['input']>;
  expirationDate?: InputMaybe<Scalars['String']['input']>;
  financingType?: InputMaybe<GQLFinancingType>;
  lenderName?: InputMaybe<Scalars['String']['input']>;
  mortgageRatePercentage?: InputMaybe<Scalars['Float']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  offerPrice: Scalars['Float']['input'];
  propertyExclusions?: InputMaybe<Scalars['String']['input']>;
  propertyInclusions?: InputMaybe<Scalars['String']['input']>;
  proposedClosingTimeframe?: InputMaybe<Scalars['Int']['input']>;
  releaseOfEarnestMoney?: InputMaybe<GQLReleaseOfEarnestMoneyInput>;
  sellerPaidClosingCostsAmount?: InputMaybe<Scalars['Float']['input']>;
  sellerPaidClosingCostsPercentage?: InputMaybe<Scalars['Float']['input']>;
  status: GQLOfferStatus;
  terminationFee?: InputMaybe<Scalars['Float']['input']>;
};

export type GQLCreateOrUpdateCoverSheetContactInput = {
  entityId?: InputMaybe<Scalars['String']['input']>;
  entityType: Scalars['String']['input'];
  googleId?: InputMaybe<Scalars['String']['input']>;
  interactionId: Scalars['String']['input'];
  interactionType: GQLInteractionType;
  teamId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type GQLCreateOutsideBrokerageInput = {
  accountingSupplierId: Scalars['String']['input'];
  address?: InputMaybe<GQLFullAddressInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type GQLCreateTemplateCategoryV2Input = {
  checklistType: GQLChecklistType;
  isSystem: Scalars['Boolean']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sortPriority: Scalars['Int']['input'];
  transactionPhase: GQLTransactionPhase;
};

export type GQLCreateTemplateRuleV2Input = {
  definition: GQLRuleDefinitionInput;
  documentProcessor?: InputMaybe<GQLRuleDocumentProcessor>;
  itemVersionId?: InputMaybe<Scalars['ID']['input']>;
  multiDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pagesRange?: InputMaybe<Array<Scalars['PageRange']['input']>>;
  templateVersionId?: InputMaybe<Scalars['ID']['input']>;
  type: GQLRuleType;
};

export type GQLCursorPaginationInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<InputMaybe<GQLFacetFilter>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<GQLDatagridSortModel>>>;
};

export type GQLDatagridSortModel = {
  field: Scalars['String']['input'];
  segment?: InputMaybe<Scalars['String']['input']>;
  sort: GQLSortDirection;
};

export enum GQLDefaultContactType {
  escrowOfficer = 'escrowOfficer',
  titleOfficer = 'titleOfficer'
}

export type GQLDefinitionConditionInput = {
  all?: InputMaybe<Array<GQLDefinitionConditionInput>>;
  any?: InputMaybe<Array<GQLDefinitionConditionInput>>;
  fact?: InputMaybe<Scalars['String']['input']>;
  operator?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['JSONObject']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['PositiveInt']['input']>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type GQLDefinitionEventInput = {
  params?: InputMaybe<Scalars['JSONObject']['input']>;
  type: Scalars['String']['input'];
};

export type GQLDefinitionEventUpdateInput = {
  params?: InputMaybe<Scalars['JSONObject']['input']>;
  type: Scalars['String']['input'];
};

export type GQLDelegateGroupInput = {
  id: Scalars['ID']['input'];
};

export type GQLDelegateGroupsInput = {
  delegatedTo?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  onBehalfOf?: InputMaybe<Scalars['ID']['input']>;
  page: Scalars['Int']['input'];
  perPage: Scalars['Int']['input'];
  sort?: InputMaybe<Array<GQLDatagridSortModel>>;
};

export type GQLDeleteAttachmentsInput = {
  attachmentId: Scalars['ID']['input'];
};

export type GQLDeleteCoverSheetEntityInput = {
  entity: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  interactionId: Scalars['String']['input'];
  interactionType: GQLInteractionType;
};

export type GQLDeleteDocumentInput = {
  documentId: Scalars['ID']['input'];
};

export type GQLDeleteDocumentInputInput = {
  documentId: Scalars['ID']['input'];
  inputId: Scalars['ID']['input'];
  pageId: Scalars['ID']['input'];
};

export type GQLDeleteDocumentsByStatusInput = {
  currentStatus: GQLInteractionDocumentStatus;
};

export type GQLDeleteEnvelopeInputs = {
  /** The docusign envelope id of envelope to delete */
  envelopeId: Scalars['ID']['input'];
};

export type GQLDeleteMlsMutationInput = {
  id: Scalars['ID']['input'];
};

export type GQLDeprovisionIdentityUserActivePipeInput = {
  disableRecentlyActiveCheck?: InputMaybe<Scalars['Boolean']['input']>;
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
};

export type GQLDeprovisionIdentityUserFollowUpBossInput = {
  disableAssignedPeopleCheck?: InputMaybe<Scalars['Boolean']['input']>;
  disableRecentlyActiveCheck?: InputMaybe<Scalars['Boolean']['input']>;
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  trashPeople?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQLDeprovisionMarqUserInput = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  groupIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  teamId: Scalars['String']['input'];
};

export type GQLDispatchStatementsNotificationsInput = {
  lastModifiedAt: Scalars['String']['input'];
  statementTypes: Array<GQLPaymentTransactionStatementType>;
};

export enum GQLDocTemplateAppearsIn {
  client_group = 'client_group',
  dual = 'dual',
  landlord = 'landlord',
  listing = 'listing',
  offer = 'offer',
  referral = 'referral',
  tenant = 'tenant'
}

export type GQLDocTemplateInput = {
  id: Scalars['ID']['input'];
  isExternalId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQLDocTemplateInputGroupInput = {
  inputIdx: Scalars['String']['input'];
  pageIdx: Scalars['Int']['input'];
};

export type GQLDocTemplateInputOptionInput = {
  left?: InputMaybe<Scalars['Float']['input']>;
  reportingName?: InputMaybe<Scalars['String']['input']>;
  tabIndex?: InputMaybe<Scalars['Int']['input']>;
  top?: InputMaybe<Scalars['Float']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export enum GQLDocTemplatePageInputImageType {
  ALT = 'ALT',
  MAIN = 'MAIN'
}

export type GQLDocTemplateVersionInput = {
  id: Scalars['ID']['input'];
  isExternalId?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum GQLDocTemplateVersionStatus {
  draft = 'draft',
  published = 'published'
}

export type GQLDocumentForUpload = {
  name: Scalars['String']['input'];
};

export type GQLDownloadDocumentsInput = {
  documentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GQLDuplicateDocumentInput = {
  documentId: Scalars['ID']['input'];
};

export type GQLDuplicateEnvelopeInputs = {
  /** A list of documents from the envelope to duplicate */
  documentIds: Array<Scalars['String']['input']>;
  /** The docusign envelope id of envelope to duplicate */
  envelopeId: Scalars['ID']['input'];
};

export type GQLEditCommentInput = {
  comment: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type GQLEditMlsMutationInput = {
  displayName: Scalars['String']['input'];
  hintText?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  locales: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  vendor: Scalars['String']['input'];
};

export type GQLEditNoteInput = {
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export enum GQLEnvelopePreviewRecipientType {
  agent = 'agent',
  appraiser = 'appraiser',
  attorney = 'attorney',
  broker = 'broker',
  client = 'client',
  closingAttorney = 'closingAttorney',
  counterpartyAgent = 'counterpartyAgent',
  counterpartyAttorney = 'counterpartyAttorney',
  counterpartyClient = 'counterpartyClient',
  counterpartySupervisingBroker = 'counterpartySupervisingBroker',
  counterpartyTransactionCoordinator = 'counterpartyTransactionCoordinator',
  escrowOfficer = 'escrowOfficer',
  homeWarranty = 'homeWarranty',
  lender = 'lender',
  officeManager = 'officeManager',
  other = 'other',
  propertyManagingAgent = 'propertyManagingAgent',
  supervisingBroker = 'supervisingBroker',
  titleOfficer = 'titleOfficer',
  transactionCoordinator = 'transactionCoordinator'
}

export type GQLEnvelopeRecipient = {
  deliveryOrder?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  emailBody?: InputMaybe<Scalars['String']['input']>;
  emailSubject: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  recipientType?: InputMaybe<GQLInteractionEnvelopeRecipientType>;
};

export type GQLEscalateDocumentInput = {
  documentId: Scalars['ID']['input'];
  documentVersionId: Scalars['ID']['input'];
  targetTier: GQLTargetTier;
};

export enum GQLExternalServicesErrorType {
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  SERVER_ERROR = 'SERVER_ERROR'
}

export type GQLFacetFilter = {
  facet?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type GQLFactsAndFiguresInput = {
  filters?: InputMaybe<Array<InputMaybe<GQLFacetFilter>>>;
};

export type GQLFeatureFlagAttributeInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  teams?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQLFeatureFlagEvaluationInput = {
  attributes?: InputMaybe<GQLFeatureFlagAttributeInput>;
  flag: Scalars['String']['input'];
  key: Scalars['String']['input'];
  kind: Scalars['String']['input'];
};

export enum GQLFileUploadStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  SUCCEEDED = 'SUCCEEDED',
  UPLOADING = 'UPLOADING'
}

export enum GQLFinalizeTransactionErrorType {
  CLOSE_DATE_READY = 'CLOSE_DATE_READY',
  COMMISSION_STATUS_READY = 'COMMISSION_STATUS_READY',
  COMPLIANCE_COMPLETED = 'COMPLIANCE_COMPLETED',
  PAYMENT_RECEIVED = 'PAYMENT_RECEIVED',
  SETTLEMENT_CONTACT_APPROVED = 'SETTLEMENT_CONTACT_APPROVED',
  STATEMENTS_SENT = 'STATEMENTS_SENT',
  TRANSACTION_STATUS_READY = 'TRANSACTION_STATUS_READY'
}

export enum GQLFinalizedBy {
  AUTO_CLOSED = 'AUTO_CLOSED',
  INSTANT_PAID = 'INSTANT_PAID',
  MANUALLY_CLOSED = 'MANUALLY_CLOSED'
}

export enum GQLFinancingType {
  bond = 'bond',
  cash = 'cash',
  conventional = 'conventional',
  fha = 'fha',
  other = 'other',
  sellerFinancing = 'sellerFinancing',
  va = 'va'
}

export enum GQLFollowUpBossUserRoleWithoutOwner {
  Admin = 'Admin',
  Agent = 'Agent',
  Broker = 'Broker',
  Lender = 'Lender'
}

export enum GQLFormBuilderConditionOperator {
  excludesAll = 'excludesAll',
  includesAll = 'includesAll',
  includesOne = 'includesOne'
}

export enum GQLFormBuilderFieldType {
  actionButton = 'actionButton',
  agentSelect = 'agentSelect',
  autofill = 'autofill',
  checkbox = 'checkbox',
  date = 'date',
  hidden = 'hidden',
  label = 'label',
  money = 'money',
  number = 'number',
  numericStepper = 'numericStepper',
  percentage = 'percentage',
  phone = 'phone',
  radio = 'radio',
  select = 'select',
  state = 'state',
  text = 'text',
  textarea = 'textarea',
  tiledRadio = 'tiledRadio',
  zip = 'zip'
}

export enum GQLFormBuilderIcon {
  autoAwesome = 'autoAwesome',
  dollar = 'dollar',
  info = 'info',
  list = 'list',
  people = 'people',
  percentage = 'percentage',
  sign = 'sign',
  uploadFile = 'uploadFile'
}

export type GQLFormBuilderInput = {
  formBuilderType: GQLFormBuilderType;
  state: Scalars['String']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};

export enum GQLFormBuilderLayoutComponentType {
  collapsible = 'collapsible',
  divider = 'divider',
  field = 'field',
  label = 'label'
}

export enum GQLFormBuilderType {
  buyerAgreement = 'buyerAgreement',
  clientGroupBuyerProfile = 'clientGroupBuyerProfile',
  offerOnListing = 'offerOnListing',
  offerOnListingAccept = 'offerOnListingAccept',
  offerOnListingBuyerAccept = 'offerOnListingBuyerAccept',
  offerOnListingCounter = 'offerOnListingCounter'
}

export type GQLFullAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street1?: InputMaybe<Scalars['String']['input']>;
  street2?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type GQLGenerateFileUploadUrlsInput = {
  names: Array<Scalars['String']['input']>;
};

export type GQLGetEnvelopeDocumentInput = {
  id: Scalars['ID']['input'];
};

export type GQLGetEnvelopeInput = {
  envelopeId: Scalars['ID']['input'];
};

export type GQLGetEnvelopeSigningUrlInput = {
  returnUrl: Scalars['String']['input'];
};

export type GQLGetEnvelopesInput = {
  needsAttention?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export enum GQLHomePageSectionType {
  FACTS_AND_FIGURES = 'FACTS_AND_FIGURES',
  NEEDS_ATTENTION = 'NEEDS_ATTENTION',
  QUICK_LINKS = 'QUICK_LINKS',
  SIDE_LISTINGS = 'SIDE_LISTINGS',
  TRANSACTION_SEARCH_LAST_VIEWED = 'TRANSACTION_SEARCH_LAST_VIEWED'
}

export enum GQLHomeWarrantyIntendedPropertyUse {
  INVESTMENT_PROPERTY = 'INVESTMENT_PROPERTY',
  PRIMARY_RESIDENCE = 'PRIMARY_RESIDENCE',
  SECONDARY_RESIDENCE = 'SECONDARY_RESIDENCE'
}

export type GQLHomeWarrantyPricingFilter = {
  parentProductId?: InputMaybe<Scalars['ID']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  propertyType?: InputMaybe<GQLPropertyType>;
  sort?: InputMaybe<Scalars['String']['input']>;
  sqft?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export enum GQLHomeWarrantyProductType {
  ADDON = 'ADDON',
  PLAN = 'PLAN'
}

export type GQLHomeWarrantyVendorsFilter = {
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum GQLIdentityBrokerAssignmentType {
  BROKERAGE = 'BROKERAGE',
  TEAM = 'TEAM'
}

export enum GQLIdentityContactType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE'
}

export type GQLIdentityCreateAddressInput = {
  city: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: GQLIdentityStateCode;
  street1: Scalars['String']['input'];
  street2?: InputMaybe<Scalars['String']['input']>;
};

export enum GQLIdentityExpirationStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  EXPIRING_SOON = 'EXPIRING_SOON',
  NO_EXPIRATION = 'NO_EXPIRATION'
}

export enum GQLIdentityServiceErrorReasonsType {
  field = 'field',
  resource = 'resource'
}

export enum GQLIdentityStateCode {
  /** Alaska */
  AK = 'AK',
  /** Alabama */
  AL = 'AL',
  /** Arkansas */
  AR = 'AR',
  /** American Samoa */
  AS = 'AS',
  /** Arizona */
  AZ = 'AZ',
  /** California */
  CA = 'CA',
  /** Colorado */
  CO = 'CO',
  /** Connecticut */
  CT = 'CT',
  /** District of Columbia */
  DC = 'DC',
  /** Delaware */
  DE = 'DE',
  /** Florida */
  FL = 'FL',
  /** Federated States of Micronesia */
  FM = 'FM',
  /** Georgia */
  GA = 'GA',
  /** Guam */
  GU = 'GU',
  /** Hawaii */
  HI = 'HI',
  /** Iowa */
  IA = 'IA',
  /** Idaho */
  ID = 'ID',
  /** Illinois */
  IL = 'IL',
  /** Indiana */
  IN = 'IN',
  /** Kansas */
  KS = 'KS',
  /** Kentucky */
  KY = 'KY',
  /** Louisiana */
  LA = 'LA',
  /** Massachusetts */
  MA = 'MA',
  /** Maryland */
  MD = 'MD',
  /** Maine */
  ME = 'ME',
  /** Marshall Islands */
  MH = 'MH',
  /** Michigan */
  MI = 'MI',
  /** Minnesota */
  MN = 'MN',
  /** Missouri */
  MO = 'MO',
  /** Northern Mariana Islands */
  MP = 'MP',
  /** Mississippi */
  MS = 'MS',
  /** Montana */
  MT = 'MT',
  /** North Carolina */
  NC = 'NC',
  /** North Dakota */
  ND = 'ND',
  /** Nebraska */
  NE = 'NE',
  /** New Hampshire */
  NH = 'NH',
  /** New Jersey */
  NJ = 'NJ',
  /** New Mexico */
  NM = 'NM',
  /** Nevada */
  NV = 'NV',
  /** New York */
  NY = 'NY',
  /** Ohio */
  OH = 'OH',
  /** Oklahoma */
  OK = 'OK',
  /** Oregon */
  OR = 'OR',
  /** Pennsylvania */
  PA = 'PA',
  /** Puerto Rico */
  PR = 'PR',
  /** Palau */
  PW = 'PW',
  /** Rhode Island */
  RI = 'RI',
  /** South Carolina */
  SC = 'SC',
  /** South Dakota */
  SD = 'SD',
  /** Tennessee */
  TN = 'TN',
  /** Texas */
  TX = 'TX',
  /** Utah */
  UT = 'UT',
  /** Virginia */
  VA = 'VA',
  /** Virgin Islands */
  VI = 'VI',
  /** Vermont */
  VT = 'VT',
  /** Washington */
  WA = 'WA',
  /** Wisconsin */
  WI = 'WI',
  /** West Virginia */
  WV = 'WV',
  /** Wyoming */
  WY = 'WY'
}

export enum GQLIdentitySupportedCountryCode {
  /** United States */
  US = 'US'
}

export enum GQLIdentitySupportedStateCode {
  /** Arizona */
  AZ = 'AZ',
  /** California */
  CA = 'CA',
  /** Colorado */
  CO = 'CO',
  /** District of Columbia */
  DC = 'DC',
  /** Florida */
  FL = 'FL',
  /** Georgia */
  GA = 'GA',
  /** Kentucky */
  KY = 'KY',
  /** Massachusetts */
  MA = 'MA',
  /** Maryland */
  MD = 'MD',
  /** North Carolina */
  NC = 'NC',
  /** New Jersey */
  NJ = 'NJ',
  /** New York */
  NY = 'NY',
  /** Ohio */
  OH = 'OH',
  /** Oregon */
  OR = 'OR',
  /** South Carolina */
  SC = 'SC',
  /** Texas */
  TX = 'TX',
  /** Virginia */
  VA = 'VA',
  /** Washington */
  WA = 'WA'
}

export type GQLIdentityTeamExternalServicesAddUserToFollowUpBossInput = {
  dryRun: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<GQLFollowUpBossUserRoleWithoutOwner>;
};

export enum GQLIdentityTeamExternalServicesErrorType {
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  SERVER_ERROR = 'SERVER_ERROR'
}

export enum GQLIdentityTeamGTMSegemntCode {
  CORE = 'CORE',
  EMERGING = 'EMERGING',
  LIMITED_OFFERING = 'LIMITED_OFFERING',
  ON_DEMAND = 'ON_DEMAND',
  STRATEGIC = 'STRATEGIC'
}

export enum GQLIdentityTeamMemberContactType {
  escrow_officer = 'escrow_officer',
  title_officer = 'title_officer'
}

export type GQLIdentityTeamMemberInput = {
  teamId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type GQLIdentityTeamMemberPreferenceMlsService = {
  id: Scalars['ID']['input'];
  primary: Scalars['Boolean']['input'];
};

export enum GQLIdentityTeamMemberRoleCode {
  /** Agent */
  AGENT = 'AGENT',
  /** Lead */
  LEAD = 'LEAD',
  /** Member */
  MEMBER = 'MEMBER',
  /** Referral agent */
  REFERRAL_AGENT = 'REFERRAL_AGENT',
  /** Transaction coordinator */
  TRANSACTION_COORDINATOR = 'TRANSACTION_COORDINATOR'
}

export enum GQLIdentityTeamMemberStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  OFFBOARDING = 'OFFBOARDING',
  ONBOARDING = 'ONBOARDING'
}

export enum GQLIdentityTeamStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum GQLIdentityTeamTypeCode {
  EXPANSION_MARKET = 'EXPANSION_MARKET',
  EXTENDED_OFFERING_CO_BRAND = 'EXTENDED_OFFERING_CO_BRAND',
  EXTENDED_OFFERING_STANDALONE = 'EXTENDED_OFFERING_STANDALONE',
  TRADITIONAL = 'TRADITIONAL'
}

export enum GQLIdentityUserErrorCode {
  UNKNOWN = 'UNKNOWN'
}

export type GQLIdentityUserExternalServiceProvisionActivePipeInput = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  integrationName?: InputMaybe<Scalars['String']['input']>;
  integrationUserId?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  organisationalunit_id?: InputMaybe<Scalars['Int']['input']>;
  teamId: Scalars['String']['input'];
};

export type GQLIdentityUserExternalServiceProvisionFollowUpBossInput = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['String']['input'];
};

export type GQLIdentityUserExternalServiceProvisionMarqUserInput = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<GQLMarqUserRoles>;
  teamId: Scalars['String']['input'];
};

export enum GQLIdentityUserExternalServicesErrorType {
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  SERVER_ERROR = 'SERVER_ERROR'
}

export enum GQLIdentityUserRoleCode {
  /** Administrator */
  ADMIN = 'ADMIN',
  /** Auditor */
  AUDITOR = 'AUDITOR',
  /** Broker */
  BROKER = 'BROKER',
  /** Payment specialist */
  PAYMENT_SPECIALIST = 'PAYMENT_SPECIALIST',
  /** TRANSACTION_COORDINATOR */
  TRANSACTION_COORDINATOR = 'TRANSACTION_COORDINATOR'
}

export enum GQLIdentityUserStatus {
  ACTIVE = 'ACTIVE',
  NONE = 'NONE',
  SUSPENDED = 'SUSPENDED'
}

export type GQLIdentityUserTransactionPermissionsInput = {
  id: Scalars['ID']['input'];
  permissions: GQLUpdateTransactionPermissionsUserInput;
};

export type GQLImageForUpload = {
  name: Scalars['String']['input'];
};

export enum GQLInitialsGroupFormation {
  grid = 'grid',
  horizontal = 'horizontal',
  vertical = 'vertical'
}

export type GQLInitialsGroupItemInput = {
  left: Scalars['Float']['input'];
  recipientId: Scalars['String']['input'];
  top: Scalars['Float']['input'];
};

/**
 * Injected field types supported by core service.
 * See https://github.com/reside-eng/core-service/blob/main/src/modules/interactions/services/documents/types/index.ts#L281
 */
export enum GQLInjectedFieldType {
  checkbox = 'checkbox',
  date = 'date',
  money = 'money',
  number = 'number',
  phone = 'phone',
  radio = 'radio',
  text = 'text',
  textarea = 'textarea'
}

export type GQLInputInput = {
  applyCommaSeparator?: InputMaybe<Scalars['Boolean']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  fontSize?: InputMaybe<Scalars['Float']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  left?: InputMaybe<Scalars['Float']['input']>;
  lineHeight?: InputMaybe<Scalars['Float']['input']>;
  maxLength?: InputMaybe<Scalars['String']['input']>;
  maxLengths?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  optional?: InputMaybe<Scalars['Boolean']['input']>;
  optionalSide?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<GQLInputOptionInput>>;
  pageId?: InputMaybe<Scalars['ID']['input']>;
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  recipientId?: InputMaybe<Scalars['String']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  suffix?: InputMaybe<Scalars['String']['input']>;
  tabIndex?: InputMaybe<Scalars['Int']['input']>;
  targetFieldNames?: InputMaybe<Scalars['JSONObject']['input']>;
  textAlign?: InputMaybe<Scalars['String']['input']>;
  textIndent?: InputMaybe<Scalars['Float']['input']>;
  top?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<GQLInputType>;
  typeFace?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type GQLInputOptionInput = {
  left?: InputMaybe<Scalars['Float']['input']>;
  reportingName?: InputMaybe<Scalars['String']['input']>;
  tabIndex?: InputMaybe<Scalars['Int']['input']>;
  top?: InputMaybe<Scalars['Float']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum GQLInputType {
  checkbox = 'checkbox',
  date = 'date',
  datesToWords = 'datesToWords',
  decimalsToWords = 'decimalsToWords',
  dropdown = 'dropdown',
  image = 'image',
  initial = 'initial',
  initialsGroup = 'initialsGroup',
  math = 'math',
  multiline = 'multiline',
  numbersToWords = 'numbersToWords',
  preparedDate = 'preparedDate',
  radio = 'radio',
  signature = 'signature',
  signatureDate = 'signatureDate',
  strikethrough = 'strikethrough',
  string = 'string',
  textarea = 'textarea'
}

export type GQLInstallmentCreateInput = {
  amount: Scalars['Float']['input'];
  closeDate: Scalars['String']['input'];
};

export type GQLInstallmentMutationInput = {
  cancel?: InputMaybe<Array<Scalars['ID']['input']>>;
  create?: InputMaybe<Array<GQLInstallmentCreateInput>>;
  update?: InputMaybe<Array<GQLInstallmentUpdateInput>>;
};

export type GQLInstallmentUpdateInput = {
  amount: Scalars['Float']['input'];
  closeDate: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export enum GQLIntegrations {
  googleCalendar = 'googleCalendar',
  googlePeople = 'googlePeople'
}

export enum GQLInteractionAttachmentType {
  coverLetter = 'coverLetter',
  disclosures = 'disclosures',
  preQual = 'preQual',
  proofOfFunds = 'proofOfFunds'
}

export type GQLInteractionContactGeneralInput = {
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
};

export type GQLInteractionContactSuggestionInput = {
  excludeNonCompliantTeams?: InputMaybe<Scalars['Boolean']['input']>;
  includeReferralAgents?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchValue: Scalars['String']['input'];
};

export enum GQLInteractionContactType {
  agent = 'agent',
  appraiser = 'appraiser',
  attorney = 'attorney',
  client = 'client',
  closingAttorney = 'closingAttorney',
  counterpartyAgent = 'counterpartyAgent',
  counterpartyAttorney = 'counterpartyAttorney',
  counterpartyClient = 'counterpartyClient',
  counterpartyTransactionCoordinator = 'counterpartyTransactionCoordinator',
  escrowOfficer = 'escrowOfficer',
  homeWarranty = 'homeWarranty',
  lender = 'lender',
  other = 'other',
  propertyManagingAgent = 'propertyManagingAgent',
  titleOfficer = 'titleOfficer',
  transactionCoordinator = 'transactionCoordinator'
}

export type GQLInteractionContactsFilter = {
  types?: InputMaybe<Array<InputMaybe<GQLInteractionContactType>>>;
};

export type GQLInteractionContactsInput = {
  filter?: InputMaybe<GQLInteractionContactsFilter>;
};

export enum GQLInteractionDocumentStatus {
  active = 'active',
  draft = 'draft',
  inactive = 'inactive',
  sent = 'sent'
}

/**
 * Use to retrieve a subset of documents
 * instead of the entire list
 */
export type GQLInteractionDocumentsFilter = {
  facets?: InputMaybe<Array<InputMaybe<GQLFacetFilter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<GQLInteractionDocumentStatus>;
};

export type GQLInteractionDocumentsInput = {
  filter?: InputMaybe<GQLInteractionDocumentsFilter>;
};

export enum GQLInteractionEnvelopeRecipientType {
  CarbonCopy = 'CarbonCopy',
  CertifiedDelivery = 'CertifiedDelivery',
  Editor = 'Editor',
  InPersonSigner = 'InPersonSigner',
  Signer = 'Signer'
}

export type GQLInteractionInfoInput = {
  id: Scalars['ID']['input'];
};

export type GQLInteractionLocationInput = {
  city: Scalars['String']['input'];
  county: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street1: Scalars['String']['input'];
  street2?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type GQLInteractionMutationInput = {
  id: Scalars['ID']['input'];
  type: GQLInteractionType;
};

export type GQLInteractionQueryInput = {
  id: Scalars['ID']['input'];
  type: GQLInteractionType;
};

export enum GQLInteractionStatus {
  acceptancePending = 'acceptancePending',
  accepted = 'accepted',
  accepting = 'accepting',
  active = 'active',
  activeCont = 'activeCont',
  backUp = 'backUp',
  buyerCountered = 'buyerCountered',
  cancellationPending = 'cancellationPending',
  cancelled = 'cancelled',
  closed = 'closed',
  comingSoon = 'comingSoon',
  counterOfferSent = 'counterOfferSent',
  countering = 'countering',
  expired = 'expired',
  handshake = 'handshake',
  lost = 'lost',
  noStatus = 'noStatus',
  offMarket = 'offMarket',
  onMarket = 'onMarket',
  pending = 'pending',
  preListing = 'preListing',
  ratified = 'ratified',
  rejected = 'rejected',
  sellerCountered = 'sellerCountered',
  underReview = 'underReview',
  withdrawn = 'withdrawn'
}

export type GQLInteractionSyncContactInput = {
  type: GQLDefaultContactType;
};

export enum GQLInteractionType {
  clientGroup = 'clientGroup',
  lease = 'lease',
  listing = 'listing',
  offer = 'offer',
  referral = 'referral',
  userTemplate = 'userTemplate'
}

export type GQLInteractionsInput = {
  includeContacts?: InputMaybe<Scalars['Boolean']['input']>;
  isGlobal?: InputMaybe<Scalars['Boolean']['input']>;
  pagination?: InputMaybe<GQLPaginationInput>;
  type: GQLInteractionType;
};

export type GQLInteractionsMutationInput = {
  type: GQLInteractionType;
};

export type GQLItemAssignV2Input = {
  checklistItemTemplateId: Scalars['ID']['input'];
  checklistTemplateCategoryId: Scalars['ID']['input'];
};

export type GQLItemRelationUpdateV2Input = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  checklistItemTemplateId: Scalars['ID']['input'];
  required?: InputMaybe<Scalars['Boolean']['input']>;
  sortPriority?: InputMaybe<Scalars['Int']['input']>;
};

export type GQLItemTemplateListFilter = {
  acceptWhenAutoAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  documentType?: InputMaybe<Array<GQLChecklistItemDocumentType>>;
  isTierOneEligible?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  riskLevel?: InputMaybe<Array<GQLRiskLevel>>;
  shouldAutoAssign?: InputMaybe<Scalars['Boolean']['input']>;
  shouldValidateTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<GQLVersionStatus>>;
  transactionPhase?: InputMaybe<GQLTransactionPhase>;
};

export type GQLItemUnassignV2Input = {
  checklistItemTemplateId: Scalars['ID']['input'];
};

export type GQLLastViewedLocationMutationInput = {
  description: Scalars['String']['input'];
  path: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export enum GQLLeaseRepresentation {
  dual = 'dual',
  landlord = 'landlord',
  tenant = 'tenant'
}

export type GQLListViewOptionsInput = {
  facetFilters?: InputMaybe<Array<InputMaybe<GQLFacetFilter>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortModel?: InputMaybe<Array<InputMaybe<GQLSortModel>>>;
  strategy?: InputMaybe<GQLStrategy>;
};

export type GQLListingMutationInput = {
  /** The id of the listing being mutated */
  id: Scalars['ID']['input'];
};

export type GQLListingOfferInput = {
  id: Scalars['ID']['input'];
};

export type GQLListingOffersInput = {
  sortModel?: InputMaybe<Array<GQLDatagridSortModel>>;
  status?: InputMaybe<GQLOfferStatus>;
};

export type GQLListingQueryInput = {
  id: Scalars['ID']['input'];
};

export enum GQLListingStatus {
  active = 'active',
  activeCont = 'activeCont',
  backUp = 'backUp',
  closed = 'closed',
  comingSoon = 'comingSoon',
  expired = 'expired',
  lost = 'lost',
  noStatus = 'noStatus',
  offMarket = 'offMarket',
  onMarket = 'onMarket',
  pending = 'pending',
  preListing = 'preListing',
  rejected = 'rejected',
  withdrawn = 'withdrawn'
}

export type GQLLobAutocompleteInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  prefix: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export enum GQLLocaleType {
  city = 'city',
  country = 'country',
  county = 'county',
  state = 'state'
}

export type GQLLocalesOptionsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<GQLLocaleType>;
};

export type GQLMLSVendorInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  vendor?: InputMaybe<Scalars['String']['input']>;
};

export type GQLMarqGroupSyncInput = {
  groupId: Scalars['String']['input'];
};

export type GQLMarqGroupsAddInput = {
  groupIds: Array<Scalars['String']['input']>;
};

export type GQLMarqUserRoles = {
  analyticsUser: Scalars['Boolean']['input'];
  dataAdmin: Scalars['Boolean']['input'];
  documentApprover: Scalars['Boolean']['input'];
  printApprover: Scalars['Boolean']['input'];
  templateAdmin: Scalars['Boolean']['input'];
};

export type GQLMlsSearchInput = {
  compareState?: InputMaybe<Scalars['String']['input']>;
  number: Scalars['String']['input'];
  vendor: Scalars['String']['input'];
};

export type GQLMoveToNewCompanyIdInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type GQLMoveToNewCompanyInput = {
  company: GQLMoveToNewCompanyIdInput;
  contact: GQLSettlementContactV2UpdateInput;
};

export enum GQLNHDReportStatus {
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  PLACED = 'PLACED',
  PROCESSING = 'PROCESSING',
  REJECTED = 'REJECTED'
}

export enum GQLNHDReportType {
  COMMERCIAL = 'COMMERCIAL',
  OWN = 'OWN',
  RENTAL = 'RENTAL',
  RESIDENTIAL = 'RESIDENTIAL'
}

export enum GQLOfferCancellationReason {
  accidentallyAccepted = 'accidentallyAccepted',
  buyerCancelled = 'buyerCancelled',
  duplicate = 'duplicate',
  other = 'other',
  sellerCancelled = 'sellerCancelled'
}

export type GQLOfferContingencyInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  numberOfDays?: InputMaybe<Scalars['Int']['input']>;
  type: GQLOfferContingencyType;
};

export enum GQLOfferContingencyType {
  appraisal = 'appraisal',
  buyerHomeSale = 'buyerHomeSale',
  commonInterestDisclosures = 'commonInterestDisclosures',
  financing = 'financing',
  inspection = 'inspection',
  other = 'other',
  preliminaryTitleReport = 'preliminaryTitleReport',
  reviewOfLeasedOrLienedItems = 'reviewOfLeasedOrLienedItems',
  reviewOfSellerDocuments = 'reviewOfSellerDocuments',
  sellerReplacementProperty = 'sellerReplacementProperty',
  woodInfestationReport = 'woodInfestationReport'
}

export enum GQLOfferExperience {
  coversheet = 'coversheet',
  featureFlag = 'featureFlag',
  lom = 'lom'
}

export type GQLOfferOnListingCancellationDetails = {
  comments?: InputMaybe<Scalars['String']['input']>;
  reason: GQLOfferCancellationReason;
};

export type GQLOfferPackagesQueryInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type GQLOfferQueryInput = {
  id: Scalars['ID']['input'];
};

export enum GQLOfferStatus {
  acceptancePending = 'acceptancePending',
  accepted = 'accepted',
  accepting = 'accepting',
  active = 'active',
  activeCont = 'activeCont',
  backUp = 'backUp',
  buyerCountered = 'buyerCountered',
  cancellationPending = 'cancellationPending',
  cancelled = 'cancelled',
  closed = 'closed',
  counterOfferSent = 'counterOfferSent',
  countering = 'countering',
  handshake = 'handshake',
  noStatus = 'noStatus',
  pending = 'pending',
  ratified = 'ratified',
  rejected = 'rejected',
  sellerCountered = 'sellerCountered',
  underReview = 'underReview'
}

export type GQLOrderHomeWarrantyInput = {
  addonIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  planId?: InputMaybe<Scalars['ID']['input']>;
  propertyDetails?: InputMaybe<GQLOrderHomeWarrantyPropertyDetailsInput>;
  transactionDetails?: InputMaybe<GQLOrderHomeWarrantyTransactionDetailsInput>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQLOrderHomeWarrantyPropertyDetailsInput = {
  hasPool: Scalars['Boolean']['input'];
  propertyType: GQLPropertyType;
  sqft: Scalars['Int']['input'];
  yearBuilt: Scalars['Int']['input'];
};

export type GQLOrderHomeWarrantyTransactionDetailsInput = {
  buyerAgentEmail: Scalars['String']['input'];
  buyerIntendedPropertyUse?: InputMaybe<GQLHomeWarrantyIntendedPropertyUse>;
  buyerPhone: Scalars['String']['input'];
  escrowEmail: Scalars['String']['input'];
  firstTimeHomeBuyer?: InputMaybe<Scalars['Boolean']['input']>;
  sellerAgentEmail: Scalars['String']['input'];
  sellerCreditAmount: Scalars['Float']['input'];
};

export type GQLOrderNHDReportInput = {
  reportType: GQLNHDReportType;
};

export type GQLOwnershipInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleOther?: InputMaybe<Scalars['String']['input']>;
};

export type GQLPaginationInput = {
  facets?: InputMaybe<Array<Scalars['String']['input']>>;
  filters?: InputMaybe<Array<InputMaybe<GQLFacetFilter>>>;
  page: Scalars['Int']['input'];
  perPage: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<GQLDatagridSortModel>>>;
};

export enum GQLPaymentCalculationErrorType {
  BAD_TRANSACTION_STATUS = 'BAD_TRANSACTION_STATUS',
  DATA_VALIDATION = 'DATA_VALIDATION',
  E_O_COLLECTION = 'E_O_COLLECTION',
  INVALID_SPLIT = 'INVALID_SPLIT',
  MISSING_DATA = 'MISSING_DATA',
  NEGATIVE_NET = 'NEGATIVE_NET',
  PRICING_PLAN = 'PRICING_PLAN',
  PRICING_PLAN_INTERNAL = 'PRICING_PLAN_INTERNAL',
  RERM_POLICY = 'RERM_POLICY',
  SALES_FORCE_CONNECTION = 'SALES_FORCE_CONNECTION',
  SALES_FORCE_MAPPING = 'SALES_FORCE_MAPPING'
}

export type GQLPaymentCommissionLeadSourcesQueryInput = {
  profileIds: Array<Scalars['String']['input']>;
};

export type GQLPaymentPortalUrlParams = {
  returnUrl: Scalars['String']['input'];
};

export type GQLPaymentSettlementContactsQueryInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  search: Scalars['String']['input'];
};

export enum GQLPaymentStatus {
  AWAITING_CERTIFICATION = 'AWAITING_CERTIFICATION',
  AWAITING_FORM = 'AWAITING_FORM',
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  CLOSING_SOON = 'CLOSING_SOON',
  COMMISSION_FORM_NOT_YET_AVAILABLE = 'COMMISSION_FORM_NOT_YET_AVAILABLE',
  EXPECTED_X_DAYS = 'EXPECTED_X_DAYS',
  PAID = 'PAID',
  PAYMENT_INITIATED = 'PAYMENT_INITIATED',
  UNKNOWN = 'UNKNOWN'
}

export enum GQLPaymentStatusGroup {
  ALL = 'ALL',
  CLOSING_SOON = 'CLOSING_SOON',
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
  NEEDS_ATTENTION = 'NEEDS_ATTENTION'
}

export type GQLPaymentTeamProfileQueryInput = {
  profileId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
};

export type GQLPaymentTeamQueryInput = {
  id: Scalars['ID']['input'];
};

export type GQLPaymentTeamsQueryInput = {
  search: Scalars['String']['input'];
  subTypes?: InputMaybe<Array<GQLTransactionTeamSubType>>;
  types: Array<GQLTransactionTeamType>;
};

export enum GQLPaymentTransactionStatementType {
  ASSOCIATE = 'ASSOCIATE',
  ESCROW = 'ESCROW',
  PARTNER = 'PARTNER',
  TEAM_LEAD = 'TEAM_LEAD'
}

export enum GQLPaymentTransactionType {
  COMMERCIAL = 'COMMERCIAL',
  CONDO_TOWNHOME_PUD = 'CONDO_TOWNHOME_PUD',
  CO_OP = 'CO_OP',
  FARM_AND_RANCH = 'FARM_AND_RANCH',
  MANUFACTURED_HOME_OR_MOBILE = 'MANUFACTURED_HOME_OR_MOBILE',
  MULTI_UNIT_1_4 = 'MULTI_UNIT_1_4',
  MULTI_UNIT_5_PLUS = 'MULTI_UNIT_5_PLUS',
  NEW_CONSTRUCTION = 'NEW_CONSTRUCTION',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
  PRE_PAID_CAP_TRANSACTION = 'PRE_PAID_CAP_TRANSACTION',
  REFERRAL_EXTERNAL = 'REFERRAL_EXTERNAL',
  REFERRAL_INTERNAL = 'REFERRAL_INTERNAL',
  REFERRAL_IN_AND_EX = 'REFERRAL_IN_AND_EX',
  RENTAL_OR_LEASE = 'RENTAL_OR_LEASE',
  RESIDENTIAL = 'RESIDENTIAL',
  SALES = 'SALES',
  SINGLE_FAMILY_RESIDENCE = 'SINGLE_FAMILY_RESIDENCE',
  TENANCY_IN_COMMON = 'TENANCY_IN_COMMON',
  VACANT_LOT_OR_LAND = 'VACANT_LOT_OR_LAND'
}

export type GQLPaymentV2TeamMemberInput = {
  id: Scalars['ID']['input'];
};

export enum GQLPermissionAction {
  ADD = 'ADD',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE'
}

export type GQLPricingStrategyInput = {
  isPersonalTransaction: Scalars['Boolean']['input'];
  leadSource?: InputMaybe<Scalars['String']['input']>;
  profileId: Scalars['ID']['input'];
  propertyType?: InputMaybe<Scalars['String']['input']>;
  representing: GQLRepresenting;
};

export enum GQLPropertyOptionGroupInteractionType {
  client_group = 'client_group',
  lease_dual = 'lease_dual',
  lease_landlord = 'lease_landlord',
  lease_landlord_tenant = 'lease_landlord_tenant',
  lease_tenant = 'lease_tenant',
  listing = 'listing',
  listing_offer = 'listing_offer',
  offer = 'offer',
  referral = 'referral'
}

export enum GQLPropertyOptionGroupSearchCriteria {
  allOf = 'allOf',
  anyOf = 'anyOf'
}

export enum GQLPropertyOptionGroupType {
  contract = 'contract',
  template = 'template'
}

export enum GQLPropertyRestrictionType {
  SPECIFIC_LOCATION = 'SPECIFIC_LOCATION',
  SPECIFIC_PROPERTIES = 'SPECIFIC_PROPERTIES'
}

export enum GQLPropertyType {
  COMMERCIAL = 'COMMERCIAL',
  CONDO_TOWNHOME_PUD = 'CONDO_TOWNHOME_PUD',
  CO_OP = 'CO_OP',
  MANUFACTURED_HOME_MOBILE = 'MANUFACTURED_HOME_MOBILE',
  MULTI_UNIT_1_4_UNITS = 'MULTI_UNIT_1_4_UNITS',
  MULTI_UNIT_5_PLUS = 'MULTI_UNIT_5_PLUS',
  NEW_CONSTRUCTION = 'NEW_CONSTRUCTION',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
  REFERRAL = 'REFERRAL',
  RENTAL_LEASE = 'RENTAL_LEASE',
  SINGLE_FAMILY_RESIDENCE = 'SINGLE_FAMILY_RESIDENCE',
  TIC = 'TIC',
  VACANT_LAND_LOT = 'VACANT_LAND_LOT'
}

export enum GQLQuickLink {
  BUYER_AGREEMENT = 'BUYER_AGREEMENT',
  CAP_REPORT = 'CAP_REPORT',
  CREATE_LEASE = 'CREATE_LEASE',
  CREATE_LISTING = 'CREATE_LISTING',
  CREATE_OFFER = 'CREATE_OFFER',
  FINANCIAL_REPORT = 'FINANCIAL_REPORT',
  GROWTH_HUB = 'GROWTH_HUB',
  GROWTH_REPORT = 'GROWTH_REPORT',
  PLATFORM_FEE_REPORT = 'PLATFORM_FEE_REPORT',
  RECEIVE_REFERRAL = 'RECEIVE_REFERRAL',
  START_REFERRAL = 'START_REFERRAL',
  TIER_PROGRESS_REPORT = 'TIER_PROGRESS_REPORT',
  USER_TEMPLATES = 'USER_TEMPLATES'
}

export type GQLQuickLinksMutationInput = {
  quickLinks: Array<GQLQuickLink>;
};

export enum GQLReconciledBy {
  AUTO_CLOSED = 'AUTO_CLOSED',
  INSTANT_PAID = 'INSTANT_PAID',
  MANUALLY_CLOSED = 'MANUALLY_CLOSED'
}

export enum GQLReferralAgreementStatus {
  completed = 'completed',
  notSent = 'notSent',
  partiallySigned = 'partiallySigned',
  sent = 'sent'
}

export enum GQLReferralClientType {
  buyer = 'buyer',
  landlord = 'landlord',
  seller = 'seller',
  tenant = 'tenant'
}

export enum GQLReferralType {
  receiving = 'receiving',
  sending = 'sending'
}

export type GQLRejectDocumentInput = {
  comment: Scalars['String']['input'];
  documentId: Scalars['ID']['input'];
};

export type GQLReleaseOfEarnestMoneyInput = {
  details?: InputMaybe<Scalars['String']['input']>;
  numberOfDays?: InputMaybe<Scalars['Int']['input']>;
};

export type GQLRemoveDocumentInput = {
  documentId: Scalars['ID']['input'];
};

export type GQLRemoveOrderHomeWarrantyInput = {
  orderId: Scalars['ID']['input'];
};

export enum GQLRepresenting {
  BUYER = 'BUYER',
  LANDLORD = 'LANDLORD',
  SELLER = 'SELLER',
  TENANT = 'TENANT'
}

export type GQLRequestCancelationInput = {
  transaction?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQLRevertEnvelopeInputs = {
  envelopeId: Scalars['ID']['input'];
};

export type GQLReviewDocumentInput = {
  documentId: Scalars['ID']['input'];
  reviewStatus: GQLAuditDocumentReviewStatus;
};

export enum GQLReviewPriority {
  P1 = 'P1'
}

export enum GQLRiskLevel {
  HIGH = 'HIGH',
  LOW = 'LOW',
  NORMAL = 'NORMAL',
  TC_HIGH = 'TC_HIGH',
  TC_LOW = 'TC_LOW',
  TC_MEDIUM = 'TC_MEDIUM'
}

export type GQLRuleDefinitionInput = {
  conditions: GQLTopLevelDefinitionConditionInput;
  event: GQLDefinitionEventInput;
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['PositiveInt']['input']>;
};

export type GQLRuleDefinitionUpdateInput = {
  conditions: GQLTopLevelDefinitionConditionInput;
  event: GQLDefinitionEventUpdateInput;
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['PositiveInt']['input']>;
};

export enum GQLRuleDocumentProcessor {
  FORM = 'FORM',
  OCR = 'OCR'
}

export enum GQLRuleType {
  EXTRACTION = 'EXTRACTION',
  INCLUSION = 'INCLUSION',
  VALIDATION = 'VALIDATION'
}

export enum GQLRunnerStatus {
  created = 'created',
  failed = 'failed',
  retrying = 'retrying',
  succeeded = 'succeeded'
}

export enum GQLRunnerType {
  CREATE_DOCUSIGN_ENVELOPE = 'CREATE_DOCUSIGN_ENVELOPE',
  CREATE_OFFER_FROM_UPLOADED_DOCUMENT = 'CREATE_OFFER_FROM_UPLOADED_DOCUMENT',
  DOWNLOAD_ALL_DOCUMENTS_BY_STATUS = 'DOWNLOAD_ALL_DOCUMENTS_BY_STATUS',
  DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT',
  DOWNLOAD_DOCUSIGN_ENVELOPE = 'DOWNLOAD_DOCUSIGN_ENVELOPE',
  DOWNLOAD_PREVIEW_DOCUMENT = 'DOWNLOAD_PREVIEW_DOCUMENT',
  FORWARD_ALL_DOCUMENTS_BY_STATUS = 'FORWARD_ALL_DOCUMENTS_BY_STATUS',
  FORWARD_DOCUMENT = 'FORWARD_DOCUMENT',
  FORWARD_ENVELOPE = 'FORWARD_ENVELOPE',
  GENERATE_OFFER = 'GENERATE_OFFER'
}

export type GQLSendAuditUpdateInput = {
  attachmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  message: Scalars['String']['input'];
  recipients: Array<Scalars['String']['input']>;
};

export type GQLSendReminderEnvelopeInputs = {
  /** The email body of the reminder */
  emailBody?: InputMaybe<Scalars['String']['input']>;
  /** The email subject of the reminder */
  emailSubject: Scalars['String']['input'];
  /** The envelope id of envelope to void */
  envelopeId: Scalars['ID']['input'];
};

export type GQLSetAuditsOwnersInput = {
  auditIds: Array<Scalars['String']['input']>;
  ownerEmails: Array<Scalars['String']['input']>;
};

export enum GQLSettlementCompanyStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export type GQLSettlementCompanyUpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<GQLSettlementCompanyStatus>;
};

export type GQLSettlementContactInput = {
  externalId: Scalars['ID']['input'];
  fileNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type GQLSettlementContactMutationInput = {
  companyName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type GQLSettlementContactV2UpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type GQLShortUrlInput = {
  shortUrlId: Scalars['String']['input'];
};

export enum GQLSidebarLinkIds {
  commission_form = 'commission_form',
  cover_letter = 'cover_letter',
  cover_sheet = 'cover_sheet',
  disclosures = 'disclosures',
  distribution_request = 'distribution_request',
  docs = 'docs',
  documents = 'documents',
  home = 'home',
  listing = 'listing',
  marketing_studio = 'marketing_studio',
  offer = 'offer',
  offers = 'offers',
  open_house = 'open_house',
  pre_qual = 'pre_qual',
  proof_of_funds = 'proof_of_funds',
  timeline = 'timeline'
}

export type GQLSignedFileOptions = {
  contentDisposition?: InputMaybe<GQLContentDisposition>;
};

export enum GQLSortDirection {
  asc = 'asc',
  desc = 'desc'
}

export type GQLSortModel = {
  field: Scalars['String']['input'];
  sort: Scalars['String']['input'];
};

export type GQLSplitDocumentInput = {
  documentId: Scalars['ID']['input'];
  splits: Array<GQLSplitItemInput>;
};

export type GQLSplitItemInput = {
  itemId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  pageFinish: Scalars['Int']['input'];
  pageStart: Scalars['Int']['input'];
};

export enum GQLStrategy {
  admin = 'admin',
  txm = 'txm',
  userTemplates = 'userTemplates'
}

export enum GQLSubmissionType {
  ADJUSTMENT = 'ADJUSTMENT',
  SUBMISSION = 'SUBMISSION'
}

export enum GQLTargetTier {
  AUDIT = 'AUDIT',
  SYSTEM = 'SYSTEM',
  T1 = 'T1'
}

export type GQLTeamMembersByTeamInput = {
  includeParentTeamRecursively?: InputMaybe<Scalars['Boolean']['input']>;
  includeSideTCs?: InputMaybe<Scalars['Boolean']['input']>;
  page: Scalars['Int']['input'];
  perPage: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<GQLDatagridSortModel>>;
};

export type GQLTeamMembersCursorPaginationInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<InputMaybe<GQLFacetFilter>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<GQLDatagridSortModel>>>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GQLTeamV2Input = {
  teamId: Scalars['ID']['input'];
};

export type GQLTemplateCategoryListV2Filter = {
  checklistType?: InputMaybe<GQLChecklistType>;
  locale?: InputMaybe<Scalars['String']['input']>;
  transactionPhase?: InputMaybe<GQLTransactionPhase>;
};

export type GQLTemplateCategoryListV2Params = {
  filter?: InputMaybe<GQLTemplateCategoryListV2Filter>;
};

export type GQLTemplateItemsInput = {
  categoryName: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  documentType?: InputMaybe<GQLChecklistItemDocumentType>;
  name: Scalars['String']['input'];
  required: Scalars['Boolean']['input'];
  riskLevel?: InputMaybe<GQLRiskLevel>;
  transactionPhase?: InputMaybe<GQLTransactionPhase>;
};

export type GQLTemplateRuleListFilter = {
  type?: InputMaybe<Array<GQLRuleType>>;
};

export type GQLTemplateRuleListV2Params = {
  filter?: InputMaybe<GQLTemplateRuleListFilter>;
};

export type GQLTopLevelDefinitionConditionInput = {
  all?: InputMaybe<Array<GQLDefinitionConditionInput>>;
  any?: InputMaybe<Array<GQLDefinitionConditionInput>>;
};

export type GQLTransactionAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type GQLTransactionAllocationInput = {
  amount: Scalars['Float']['input'];
  externalId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isDeleted: Scalars['Boolean']['input'];
  isPercent: Scalars['Boolean']['input'];
  isPersonalTransaction: Scalars['Boolean']['input'];
  isRepresentingBothParties: Scalars['Boolean']['input'];
  profile: GQLTransactionAllocationProfileInput;
  splits?: InputMaybe<GQLTransactionAllocationSplitsInput>;
};

export type GQLTransactionAllocationProfileInput = {
  externalId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type GQLTransactionAllocationSplitsInput = {
  agentPercent: Scalars['Float']['input'];
  brokeragePercent: Scalars['Float']['input'];
  parentTeamPercent: Scalars['Float']['input'];
  teamPercent: Scalars['Float']['input'];
};

export type GQLTransactionAttachmentInput = {
  externalId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isDeleted: Scalars['Boolean']['input'];
};

export enum GQLTransactionCommissionLeadSource {
  AGENT_GENERATED = 'AGENT_GENERATED',
  BTSA = 'BTSA',
  DOOR_KNOCKING = 'DOOR_KNOCKING',
  MARKETING_CHANNEL = 'MARKETING_CHANNEL',
  OFFER_WRITING = 'OFFER_WRITING',
  ONLINE = 'ONLINE',
  OPEN_HOUSE = 'OPEN_HOUSE',
  PAID = 'PAID',
  PERFORMANCE = 'PERFORMANCE',
  PROVIDED_BY_TEAM_LEAD_TO_ASSOCIATE_AGENT = 'PROVIDED_BY_TEAM_LEAD_TO_ASSOCIATE_AGENT',
  PROVIDED_BY_TEAM_OR_PARTNER_TO_ASSOCIATE_AGENT = 'PROVIDED_BY_TEAM_OR_PARTNER_TO_ASSOCIATE_AGENT',
  TEAM_PROVIDED = 'TEAM_PROVIDED',
  TEAM_PROVIDED_COLD = 'TEAM_PROVIDED_COLD',
  TEAM_PROVIDED_WARM = 'TEAM_PROVIDED_WARM',
  TEAM_REFERRAL = 'TEAM_REFERRAL',
  ZILLOW = 'ZILLOW'
}

export type GQLTransactionCommissionStatusInput = {
  demandTransactionId: Scalars['ID']['input'];
};

export enum GQLTransactionFeeFileUploadStatus {
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',
  UPLOADING = 'UPLOADING'
}

export type GQLTransactionFeeInput = {
  agentName?: InputMaybe<Scalars['String']['input']>;
  amount: Scalars['Float']['input'];
  attachments?: InputMaybe<Array<GQLTransactionAttachmentInput>>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isDeleted: Scalars['Boolean']['input'];
  isPercent: Scalars['Boolean']['input'];
  isPre: Scalars['Boolean']['input'];
  payee?: InputMaybe<GQLTransactionPartyInput>;
  payer?: InputMaybe<GQLTransactionPartyInput>;
  type: GQLTransactionFeeType;
};

export enum GQLTransactionFeeType {
  ADDITIONAL_COMMISSION = 'ADDITIONAL_COMMISSION',
  ADJUSTMENT = 'ADJUSTMENT',
  ADMIN = 'ADMIN',
  AGENT_REBATE = 'AGENT_REBATE',
  AGENT_TO_AGENT_SAME_TEAM = 'AGENT_TO_AGENT_SAME_TEAM',
  BRAND = 'BRAND',
  BROKERAGE = 'BROKERAGE',
  CHARITY = 'CHARITY',
  CHARITY_BY_TITLE = 'CHARITY_BY_TITLE',
  CLEANING = 'CLEANING',
  CLIENT_CREDIT = 'CLIENT_CREDIT',
  CLOSING_COST_CREDIT = 'CLOSING_COST_CREDIT',
  CO_BROKERAGE = 'CO_BROKERAGE',
  ECOMMISSION = 'ECOMMISSION',
  E_AND_O = 'E_AND_O',
  FLAT_BROKERAGE = 'FLAT_BROKERAGE',
  FMLS = 'FMLS',
  HOA_DOCUMENT = 'HOA_DOCUMENT',
  HOME_WARRANTY = 'HOME_WARRANTY',
  INSPECTION_REPORT = 'INSPECTION_REPORT',
  LISTING_MANAGEMENT = 'LISTING_MANAGEMENT',
  MARKETING = 'MARKETING',
  MISC_PAID_BY_TITLE = 'MISC_PAID_BY_TITLE',
  MLS_FEE_DEDUCTION = 'MLS_FEE_DEDUCTION',
  MLS_FEE_REIMBURSEMENT = 'MLS_FEE_REIMBURSEMENT',
  NATURAL_HAZARD = 'NATURAL_HAZARD',
  NOTARY = 'NOTARY',
  OTHER = 'OTHER',
  PHOTOGRAPHY = 'PHOTOGRAPHY',
  REFERRAL = 'REFERRAL',
  REPAIR = 'REPAIR',
  RERM = 'RERM',
  SALT_PAYABLE = 'SALT_PAYABLE',
  SHOWING = 'SHOWING',
  SIDE_PAID_TC = 'SIDE_PAID_TC',
  STAGING = 'STAGING',
  TC = 'TC',
  TC_BY_TITLE = 'TC_BY_TITLE',
  TEAM = 'TEAM',
  TEAM_RENTAL = 'TEAM_RENTAL',
  TEAM_SHARE = 'TEAM_SHARE',
  TRANSACTION = 'TRANSACTION',
  WIRE = 'WIRE'
}

export type GQLTransactionMutationInput = {
  id: Scalars['ID']['input'];
  interactionId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQLTransactionPartyInput = {
  address?: InputMaybe<GQLTransactionAddressInput>;
  brokerageName?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  type: GQLTransactionPartyType;
};

export enum GQLTransactionPartyType {
  BROKERAGE = 'BROKERAGE',
  EXTERNAL_AGENT = 'EXTERNAL_AGENT',
  EXTERNAL_BROKERAGE = 'EXTERNAL_BROKERAGE',
  EXTERNAL_COMPANY = 'EXTERNAL_COMPANY',
  EXTERNAL_PERSON = 'EXTERNAL_PERSON',
  OUTSIDE_BROKERAGE = 'OUTSIDE_BROKERAGE',
  PROFILE = 'PROFILE',
  REAL_ESTATE_SERVICES = 'REAL_ESTATE_SERVICES',
  REFERRAL_COMPANY = 'REFERRAL_COMPANY',
  SETTLEMENT_COMPANY = 'SETTLEMENT_COMPANY',
  SETTLEMENT_CONTACT = 'SETTLEMENT_CONTACT',
  TEAM = 'TEAM',
  TEAM_MEMBER = 'TEAM_MEMBER'
}

export enum GQLTransactionPhase {
  LISTING = 'LISTING',
  TRANSACTION = 'TRANSACTION'
}

export enum GQLTransactionProfileRoleType {
  ADMINISTRATOR = 'ADMINISTRATOR',
  ASSOCIATE_AGENT = 'ASSOCIATE_AGENT',
  BROKER = 'BROKER',
  INTERNAL_USER = 'INTERNAL_USER',
  INTERNAL_USER_ASSOCIATE = 'INTERNAL_USER_ASSOCIATE',
  PARTNER_AGENT = 'PARTNER_AGENT',
  REFERRAL_AGENT = 'REFERRAL_AGENT',
  SUPERVISING_BROKER = 'SUPERVISING_BROKER',
  TEAM_LEAD = 'TEAM_LEAD',
  TRANSACTION_COORDINATOR = 'TRANSACTION_COORDINATOR'
}

export enum GQLTransactionProfileStatusType {
  ACTIVE = 'ACTIVE',
  OFFBOARDING = 'OFFBOARDING',
  ONBOARDING = 'ONBOARDING',
  PROSPECT = 'PROSPECT',
  TERMINATED = 'TERMINATED'
}

export type GQLTransactionQueryInput = {
  id: Scalars['ID']['input'];
  interactionId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQLTransactionReferralInput = {
  amount: Scalars['Float']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  externalReferralId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isDeleted: Scalars['Boolean']['input'];
  isPercent: Scalars['Boolean']['input'];
  payee: GQLTransactionPartyInput;
};

export enum GQLTransactionSide {
  BUYER = 'BUYER',
  SELLER = 'SELLER'
}

export type GQLTransactionStatementsInput = {
  isCurrent?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum GQLTransactionStatus {
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED',
  DELETED = 'DELETED',
  DEMANDS_SENT_TO_ESCROW = 'DEMANDS_SENT_TO_ESCROW',
  INCOMING_REFERRAL = 'INCOMING_REFERRAL',
  LEASE = 'LEASE',
  LISTING_OR_LISTING_SIGNED = 'LISTING_OR_LISTING_SIGNED',
  LISTING_SOLD = 'LISTING_SOLD',
  PENDING_OR_IN_ESCROW = 'PENDING_OR_IN_ESCROW',
  PRE_RESIDE = 'PRE_RESIDE'
}

export type GQLTransactionTeamPayeesQueryInput = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type GQLTransactionTeamProfilesQueryInput = {
  includeParentTeam?: InputMaybe<Scalars['Boolean']['input']>;
  includeParentTeamRecursively?: InputMaybe<Scalars['Boolean']['input']>;
  includeSideTCs?: InputMaybe<Scalars['Boolean']['input']>;
  requireLicense?: InputMaybe<Scalars['Boolean']['input']>;
  roles?: InputMaybe<Array<GQLTransactionProfileRoleType>>;
  search: Scalars['String']['input'];
};

export enum GQLTransactionTeamSubType {
  ESCROW = 'ESCROW',
  EXPANSION_MARKET = 'EXPANSION_MARKET',
  EXTENDED_OFFER_CO_BRAND = 'EXTENDED_OFFER_CO_BRAND',
  EXTENDED_OFFER_STANDALONE = 'EXTENDED_OFFER_STANDALONE',
  EXTERNAL_BROKERAGE = 'EXTERNAL_BROKERAGE',
  REAL_ESTATE_SERVICES = 'REAL_ESTATE_SERVICES',
  REFERRAL_COMPANY = 'REFERRAL_COMPANY',
  TRADITIONAL = 'TRADITIONAL'
}

export enum GQLTransactionTeamType {
  BROKERAGE = 'BROKERAGE',
  CUSTOMER = 'CUSTOMER',
  CUSTOMER_LOST = 'CUSTOMER_LOST',
  IN_LAUNCH = 'IN_LAUNCH',
  LAUNCH_ATTRITION = 'LAUNCH_ATTRITION',
  PROSPECT = 'PROSPECT',
  SIDE = 'SIDE',
  VENDOR = 'VENDOR'
}

export type GQLUpdateAnswerInput = {
  documentId: Scalars['ID']['input'];
  inputId: Scalars['ID']['input'];
  pageId: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};

export type GQLUpdateAuditInfoInput = {
  acceptanceDate?: InputMaybe<Scalars['String']['input']>;
  buyingCommissionAmount?: InputMaybe<Scalars['Float']['input']>;
  buyingCommissionPercentage?: InputMaybe<Scalars['Float']['input']>;
  dualAgency?: InputMaybe<Scalars['Boolean']['input']>;
  expeditedTransaction?: InputMaybe<Scalars['Boolean']['input']>;
  hasHOA?: InputMaybe<Scalars['Boolean']['input']>;
  hasPool?: InputMaybe<Scalars['Boolean']['input']>;
  hasPropane?: InputMaybe<Scalars['Boolean']['input']>;
  hasSepticTank?: InputMaybe<Scalars['Boolean']['input']>;
  hasSolar?: InputMaybe<Scalars['Boolean']['input']>;
  hasWell?: InputMaybe<Scalars['Boolean']['input']>;
  historicalDisclosuresFound?: InputMaybe<Scalars['Boolean']['input']>;
  historicalDisclosuresSent?: InputMaybe<Scalars['Boolean']['input']>;
  isPersonalDeal?: InputMaybe<Scalars['Boolean']['input']>;
  listingCommissionAmount?: InputMaybe<Scalars['Float']['input']>;
  listingCommissionPercentage?: InputMaybe<Scalars['Float']['input']>;
  listingReviewed?: InputMaybe<Scalars['Boolean']['input']>;
  offerReviewed?: InputMaybe<Scalars['Boolean']['input']>;
  tenantOccupied?: InputMaybe<Scalars['Boolean']['input']>;
  yearBuilt?: InputMaybe<Scalars['Int']['input']>;
};

export type GQLUpdateChecklistItemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQLUpdateChecklistItemTemplateV2Input = {
  acceptWhenAutoAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  documentType?: InputMaybe<GQLChecklistItemDocumentType>;
  isTierOneEligible?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  shouldAutoAssign?: InputMaybe<Scalars['Boolean']['input']>;
  shouldValidateTemplate?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQLUpdateChecklistItemTemplateVersionV2Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  riskLevel?: InputMaybe<GQLRiskLevel>;
};

export type GQLUpdateChecklistTemplateInput = {
  interactionType?: InputMaybe<GQLInteractionType>;
  items?: InputMaybe<Array<GQLTemplateItemsInput>>;
  localeId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  propertyType?: InputMaybe<GQLPropertyType>;
};

export type GQLUpdateChecklistTemplateV2Input = {
  locale: Scalars['String']['input'];
};

export type GQLUpdateChecklistTemplateVersionV2Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQLUpdateCoverPhotoInput = {
  interactionFileId: Scalars['String']['input'];
};

export type GQLUpdateCoverSheetFieldData = {
  name: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type GQLUpdateCoverSheetFieldsInput = {
  entity?: InputMaybe<Scalars['String']['input']>;
  entityId?: InputMaybe<Scalars['String']['input']>;
  fields: Array<GQLUpdateCoverSheetFieldData>;
  interactionId: Scalars['String']['input'];
  interactionType: GQLInteractionType;
};

export type GQLUpdateCoversheetInput = {
  data: GQLUpdateCoversheetInputData;
  interactionId: Scalars['ID']['input'];
  interactionType: GQLAuditInteractionType;
};

export type GQLUpdateCoversheetInputData = {
  closingDate?: InputMaybe<Scalars['String']['input']>;
  leaseEndDate?: InputMaybe<Scalars['String']['input']>;
  leasePaymentDate?: InputMaybe<Scalars['String']['input']>;
  leaseStartDate?: InputMaybe<Scalars['String']['input']>;
  leaseTotalRentAmount?: InputMaybe<Scalars['Float']['input']>;
  listingPrice?: InputMaybe<Scalars['Float']['input']>;
  salesPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type GQLUpdateDelegateGroupInput = {
  delegatedToId: Array<Scalars['ID']['input']>;
  permissions: Array<Scalars['String']['input']>;
};

export type GQLUpdateDocTemplateInput = {
  appearsIn?: InputMaybe<Array<GQLDocTemplateAppearsIn>>;
  formLibrary?: InputMaybe<Scalars['String']['input']>;
  formName?: InputMaybe<Scalars['String']['input']>;
  formVersion?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isCustom?: InputMaybe<Scalars['Boolean']['input']>;
  isGlobal?: InputMaybe<Scalars['Boolean']['input']>;
  isReadyForReporting?: InputMaybe<Scalars['Boolean']['input']>;
  listingOrder?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  offerOrder?: InputMaybe<Scalars['Int']['input']>;
  revisionMonth?: InputMaybe<Scalars['Int']['input']>;
  revisionYear?: InputMaybe<Scalars['Int']['input']>;
};

export type GQLUpdateDocTemplateInputInput = {
  applyCommaSeparator?: InputMaybe<Scalars['Boolean']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  coversheetValue?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  dropdownOptions?: InputMaybe<Scalars['String']['input']>;
  dropdownPlaceholder?: InputMaybe<Scalars['String']['input']>;
  fontSize?: InputMaybe<Scalars['Float']['input']>;
  formation?: InputMaybe<GQLInitialsGroupFormation>;
  formula?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Array<GQLDocTemplateInputGroupInput>>;
  height?: InputMaybe<Scalars['Float']['input']>;
  image?: InputMaybe<GQLDocTemplatePageInputImageType>;
  initialsGroup?: InputMaybe<Array<GQLInitialsGroupItemInput>>;
  injectOnly?: InputMaybe<Scalars['Boolean']['input']>;
  isInjectedMultiline?: InputMaybe<Scalars['Boolean']['input']>;
  left?: InputMaybe<Scalars['Float']['input']>;
  lineHeight?: InputMaybe<Scalars['Float']['input']>;
  maxLength?: InputMaybe<Scalars['String']['input']>;
  maxLengths?: InputMaybe<Array<Scalars['String']['input']>>;
  multiline?: InputMaybe<GQLDocTemplateInputGroupInput>;
  multilineValue?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  optional?: InputMaybe<Scalars['Boolean']['input']>;
  optionalSide?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<GQLDocTemplateInputOptionInput>>;
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  recipientId?: InputMaybe<Scalars['String']['input']>;
  reportingName?: InputMaybe<Scalars['String']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  scale?: InputMaybe<Scalars['Float']['input']>;
  suffix?: InputMaybe<Scalars['String']['input']>;
  tabIndex?: InputMaybe<Scalars['Int']['input']>;
  targetFieldNames?: InputMaybe<Scalars['JSONObject']['input']>;
  textAlign?: InputMaybe<Scalars['String']['input']>;
  textIndent?: InputMaybe<Scalars['Float']['input']>;
  top?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<GQLInputType>;
  typeFace?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type GQLUpdateDocumentInputInput = {
  documentId: Scalars['ID']['input'];
  input: GQLInputInput;
  inputId: Scalars['ID']['input'];
  pageId: Scalars['ID']['input'];
};

export type GQLUpdateDocumentStatusInput = {
  documentId: Scalars['ID']['input'];
  status: GQLInteractionDocumentStatus;
};

export type GQLUpdateDocumentsEnvelopeOrderInput = {
  /** Document ids in the order in which they should be assigned envelope orders */
  documentIds: Array<Scalars['ID']['input']>;
};

export type GQLUpdateDocumentsStatusInput = {
  currentStatus: GQLInteractionDocumentStatus;
  updatedStatus: GQLInteractionDocumentStatus;
};

export type GQLUpdateEscrowInput = {
  closingDate: Scalars['String']['input'];
  interactionId: Scalars['ID']['input'];
  interactionType: GQLAuditInteractionType;
};

export type GQLUpdateGoogleContactInput = {
  contactPayload: GQLContactInput;
  interactionId: Scalars['String']['input'];
  interactionType?: InputMaybe<Scalars['String']['input']>;
};

export type GQLUpdateIdentityBrokerageInput = {
  address?: InputMaybe<GQLCreateIdentityUserAddressInput>;
  brokerEmail?: InputMaybe<Scalars['String']['input']>;
  brokerLicenseId?: InputMaybe<Scalars['ID']['input']>;
  brokerPhone?: InputMaybe<Scalars['String']['input']>;
  brokerUserId?: InputMaybe<Scalars['ID']['input']>;
  licenseExpiration?: InputMaybe<Scalars['String']['input']>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  market?: InputMaybe<GQLIdentitySupportedStateCode>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type GQLUpdateIdentityLicenseInput = {
  expiration?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<GQLIdentitySupportedStateCode>;
};

export type GQLUpdateIdentitySignatureInput = {
  file: Scalars['String']['input'];
};

export type GQLUpdateIdentityTeamExternalServiceFollowUpBossInstanceInput = {
  adminEmail?: InputMaybe<Scalars['String']['input']>;
  apiKey?: InputMaybe<Scalars['String']['input']>;
  billingAccountId?: InputMaybe<Scalars['String']['input']>;
  luxuryPresenceApiKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pixelWidgetTrackerCode?: InputMaybe<Scalars['String']['input']>;
  supportTier?: InputMaybe<Scalars['Int']['input']>;
};

export type GQLUpdateIdentityTeamExternalServicesActivePipeOfficesInput = {
  offices: Array<Scalars['String']['input']>;
};

export type GQLUpdateIdentityTeamMemberContactInput = {
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street1?: InputMaybe<Scalars['String']['input']>;
  street2?: InputMaybe<Scalars['String']['input']>;
  titleNumber?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type GQLUpdateIdentityTeamMemberInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  licenseId?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<InputMaybe<GQLIdentityTeamMemberRoleCode>>>;
};

export type GQLUpdateIdentityTeamMemberPreferencesInput = {
  agentCommission?: InputMaybe<Scalars['String']['input']>;
  docusignCarbonCopy?: InputMaybe<Array<Scalars['String']['input']>>;
  listingPeriod?: InputMaybe<Scalars['String']['input']>;
  mlsServices?: InputMaybe<Array<GQLIdentityTeamMemberPreferenceMlsService>>;
  mlsSubscriptions?: InputMaybe<Array<Scalars['String']['input']>>;
  primaryMLS?: InputMaybe<Scalars['String']['input']>;
  sellingAgentCommission?: InputMaybe<Scalars['String']['input']>;
};

export type GQLUpdateIdentityTeamMutationInput = {
  brokerageId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAs?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  legacyExternalId?: InputMaybe<Scalars['String']['input']>;
  logoHorizontalUri?: InputMaybe<Scalars['String']['input']>;
  logoHorizontalUriAlt?: InputMaybe<Scalars['String']['input']>;
  logoUri?: InputMaybe<Scalars['String']['input']>;
  logoUriAlt?: InputMaybe<Scalars['String']['input']>;
  mailingAddress?: InputMaybe<GQLCreateIdentityUserAddressInput>;
  mlsId?: InputMaybe<Scalars['String']['input']>;
  mlsOfficeEmail?: InputMaybe<Scalars['String']['input']>;
  officeManagerUserId?: InputMaybe<Scalars['String']['input']>;
  parentTeamId?: InputMaybe<Scalars['ID']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  registeredAddress?: InputMaybe<GQLCreateIdentityUserAddressInput>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type GQLUpdateIdentityTeamSupervisingBroker = {
  email: Scalars['String']['input'];
  licenseId: Scalars['ID']['input'];
  phone: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type GQLUpdateIdentityUserAccountInfoInput = {
  address?: InputMaybe<GQLCreateIdentityUserAddressInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  primaryPhone?: InputMaybe<Scalars['String']['input']>;
};

export type GQLUpdateIdentityUserAvatarInput = {
  file?: InputMaybe<Scalars['String']['input']>;
  x?: InputMaybe<Scalars['Int']['input']>;
  y?: InputMaybe<Scalars['Int']['input']>;
};

export type GQLUpdateIdentityUserContactInput = {
  isPersonal?: InputMaybe<Scalars['Boolean']['input']>;
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type GQLUpdateIdentityUserInput = {
  address?: InputMaybe<GQLCreateIdentityUserAddressInput>;
  addressId?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<InputMaybe<GQLIdentityUserRoleCode>>>;
};

export type GQLUpdateInteractionIsArchivedInput = {
  isArchived: Scalars['Boolean']['input'];
};

export type GQLUpdateInteractionStatusInput = {
  status: GQLInteractionStatus;
};

export type GQLUpdateListingPriceHistoryInput = {
  priceHistoryId: Scalars['String']['input'];
};

export type GQLUpdateListingPriceInput = {
  interactionId: Scalars['ID']['input'];
  interactionType: GQLAuditInteractionType;
  listingPrice: Scalars['Float']['input'];
};

export type GQLUpdateLocaleInput = {
  cityName?: InputMaybe<Scalars['String']['input']>;
  countyName?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  stateName?: InputMaybe<Scalars['String']['input']>;
};

export type GQLUpdateOfferOnListingStatusInput = {
  cancellationDetails?: InputMaybe<GQLOfferOnListingCancellationDetails>;
  offerId: Scalars['String']['input'];
  status: GQLOfferStatus;
};

export type GQLUpdateOutsideBrokerageInput = {
  accountingSupplierId?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<GQLFullAddressInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type GQLUpdatePaymentTeamInput = {
  isInstantPayEligible?: InputMaybe<Scalars['Boolean']['input']>;
  useAutoSendStatements?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQLUpdatePaymentTeamMemberInput = {
  isInstantPayEligible?: InputMaybe<Scalars['Boolean']['input']>;
  licenseTerminationDate?: InputMaybe<Scalars['String']['input']>;
};

export type GQLUpdatePaymentTeamMemberPermissionsInput = {
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQLUpdatePropertyOptionGroupInput = {
  documentTemplateIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  id: Scalars['ID']['input'];
  isGlobal?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  pogInteractionType?: InputMaybe<GQLPropertyOptionGroupInteractionType>;
  priority?: InputMaybe<Scalars['Float']['input']>;
  searchCriteria?: InputMaybe<GQLPropertyOptionGroupSearchCriteria>;
  type?: InputMaybe<GQLPropertyOptionGroupType>;
};

export type GQLUpdateReferralAgentInput = {
  broker?: InputMaybe<GQLInteractionContactGeneralInput>;
  brokerage?: InputMaybe<GQLBrokerageInput>;
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  googleContactId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  supervisingBroker?: InputMaybe<GQLInteractionContactGeneralInput>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  teamName?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type GQLUpdateReferralClientInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  clientTypes?: InputMaybe<Array<GQLReferralClientType>>;
  contactSource?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  entity?: InputMaybe<GQLOwnershipInput>;
  entityType?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  googleContactId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street1?: InputMaybe<Scalars['String']['input']>;
  street2?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type GQLUpdateReferralInput = {
  client?: InputMaybe<GQLUpdateReferralClientInput>;
  commissionType?: InputMaybe<GQLCommissionType>;
  commissionValue?: InputMaybe<Scalars['Float']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  primaryAgent?: InputMaybe<GQLUpdateReferralPrimaryAgentInput>;
  secondaryAgent?: InputMaybe<GQLUpdateReferralSecondaryAgentInput>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type GQLUpdateReferralPrimaryAgentInput = {
  id: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type GQLUpdateReferralSecondaryAgentInput = {
  broker?: InputMaybe<GQLInteractionContactGeneralInput>;
  brokerage?: InputMaybe<GQLBrokerageInput>;
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  googleContactId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  supervisingBroker?: InputMaybe<GQLInteractionContactGeneralInput>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  teamName?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type GQLUpdateSalesPriceInput = {
  interactionId: Scalars['ID']['input'];
  interactionType: GQLAuditInteractionType;
  salesPrice: Scalars['Float']['input'];
};

export type GQLUpdateSettlementContactInput = {
  contact: GQLSettlementContactV2UpdateInput;
};

export type GQLUpdateTemplateCategoryV2Input = {
  isSystem?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sortPriority?: InputMaybe<Scalars['Int']['input']>;
  transactionPhase?: InputMaybe<GQLTransactionPhase>;
};

export type GQLUpdateTemplateRuleV2Input = {
  definition?: InputMaybe<GQLRuleDefinitionUpdateInput>;
  documentProcessor?: InputMaybe<GQLRuleDocumentProcessor>;
  itemVersionId?: InputMaybe<Scalars['ID']['input']>;
  multiDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pagesRange?: InputMaybe<Array<Scalars['PageRange']['input']>>;
  templateVersionId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<GQLRuleType>;
};

export type GQLUpdateTransactionInput = {
  allocations: Array<GQLTransactionAllocationInput>;
  coBrokerageFees: Array<GQLTransactionFeeInput>;
  commissionLeadSource?: InputMaybe<GQLTransactionCommissionLeadSource>;
  fees: Array<GQLTransactionFeeInput>;
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  referrals: Array<GQLTransactionReferralInput>;
  settlementContact?: InputMaybe<GQLSettlementContactInput>;
  submissionType: GQLSubmissionType;
};

/**
 * Transaction permissions are relative to the user at the root of the mutation
 * CORE POST user-settintgs/:rootUserId/submit-as-permissions [:targetUserId]
 * CORE POST user-settintgs/:rootUserId/sharing-permissions [:targetUserId]
 */
export type GQLUpdateTransactionPermissionsUserInput = {
  submitAs?: InputMaybe<Scalars['Boolean']['input']>;
  transactionSharing?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQLUploadAttachment = {
  name: Scalars['String']['input'];
};

export type GQLUploadAttachmentsInput = {
  attachments: Array<GQLUploadAttachment>;
  type: GQLInteractionAttachmentType;
};

export type GQLUploadOfferOnListingContractInput = {
  name: Scalars['String']['input'];
};

export enum GQLUploadStatus {
  completed = 'completed',
  created = 'created',
  failed = 'failed',
  processing = 'processing',
  uploading = 'uploading'
}

export enum GQLUserPaymentStatus {
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE',
  NOT_REQUIRED = 'NOT_REQUIRED'
}

export enum GQLVersionStatus {
  draft = 'draft',
  published = 'published'
}

export type GQLVoidEnvelopeInputs = {
  /** The docusign envelope id of envelope to void */
  envelopeId: Scalars['ID']['input'];
  /** The reason the envelope is being voided */
  voidReason: Scalars['String']['input'];
};

export type GQLisGoogleLinkedInput = {
  interactionId: Scalars['ID']['input'];
  interactionType?: InputMaybe<GQLInteractionType>;
};


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AuditJob": [
      "DownloadAuditDocumentsJob"
    ],
    "Auditable": [
      "CommissionNotification",
      "CommissionNotificationRecipient",
      "DelegateGroup",
      "DelegateGroupHistory",
      "DelegateGroupPermission",
      "DelegateGroupRelation",
      "History",
      "TeamMemberHistory"
    ],
    "CommissionParty": [
      "CommissionTeam",
      "CommissionTeamMember"
    ],
    "Connection": [
      "ChecklistItemTemplateV2Connection",
      "ChecklistTemplateV2Connection",
      "ChecklistTemplatesDraftsV2Connection",
      "CommunityListingsConnection",
      "EnvelopeDocumentsConnection",
      "InteractionDocumentsConnection"
    ],
    "ConnectionResult": [
      "ChecklistItemTemplateListV2Result",
      "ChecklistTemplateListV2Result",
      "ChecklistTemplatesDraftsV2Result"
    ],
    "CursorPaginationResponse": [
      "InteractionPermissionSubmitAsTeamMembersResponse",
      "InteractionPermissionSubmitAsUserResponse",
      "InteractionPermissionTransactionSharingTeamMembersResponse",
      "InteractionPermissionTransactionSharingUsersResponse"
    ],
    "Edge": [
      "ChecklistItemTemplateV2Edge",
      "ChecklistTemplateDraftsV2Edge",
      "ChecklistTemplateV2Edge",
      "CommunityListingEdge",
      "EnvelopeDocumentsEdge",
      "InteractionDocumentsEdge"
    ],
    "ErrorPayload": [
      "IdentityAddressNotFoundError",
      "IdentityLicenseDuplicateError",
      "IdentityLicenseExpiredError",
      "IdentityLicenseIsAssociatedError",
      "IdentityLicenseNotFoundError",
      "IdentityLicenseUnsupportedStateError",
      "IdentityOrganizationError",
      "IdentityTeamMemberNotFoundError",
      "IdentityTeamNotFoundError",
      "IdentityUserContactDeletePrimaryError",
      "IdentityUserContactNotFoundError",
      "IdentityUserNotFoundError"
    ],
    "FollowUpBossReportPayload": [
      "FollowUpBossReport",
      "IdentityTeamError"
    ],
    "IdentityBrokerageTeamDeleteError": [
      "IdentityBrokerageError"
    ],
    "IdentityCreateTeamMemberError": [
      "IdentityTeamMemberError"
    ],
    "IdentityCreateUserContactError": [
      "IdentityContactError"
    ],
    "IdentityLicensePayload": [
      "IdentityLicense",
      "IdentityLicenseError",
      "IdentityLicenseNotFoundError"
    ],
    "IdentityOrganizationPayload": [
      "IdentityOrganization",
      "IdentityOrganizationError"
    ],
    "IdentityTeamExternalServicesActivePipeAddOfficesPayload": [
      "IdentityTeamError",
      "IdentityTeamExternalServicesActivePipeOffices"
    ],
    "IdentityTeamFollowUpBossUserPayload": [
      "IdentityFollowUpBossUser",
      "IdentityTeamError"
    ],
    "IdentityTeamMemberDeleteErrorPayload": [
      "IdentityTeamMemberDeleteError",
      "IdentityTeamMemberNotFoundError"
    ],
    "IdentityTeamPayload": [
      "IdentityTeam",
      "IdentityTeamError",
      "IdentityTeamNotFoundError"
    ],
    "IdentityUpdateTeamMemberPayloadError": [
      "IdentityTeamMemberNotFoundError",
      "IdentityTeamMemberUpdateError"
    ],
    "IdentityUpdateUserContactError": [
      "IdentityContactError"
    ],
    "IdentityUserContactDeleteErrorPayload": [
      "IdentityUserContactDeleteError",
      "IdentityUserContactDeletePrimaryError",
      "IdentityUserContactNotFoundError"
    ],
    "IdentityUserExternalServiceActivePipeProvisionPayload": [
      "IdentityUserError",
      "IdentityUserExternalServiceProvisionActivePipeResponse"
    ],
    "IdentityUserExternalServiceMarqUserPayload": [
      "IdentityUserError",
      "IdentityUserExternalServiceProvisionMarqUserReponse"
    ],
    "IdentityUserFollowUpBossDisablePayload": [
      "IdentityFollowUpBossUser",
      "IdentityUserError"
    ],
    "IdentityUserLicenseAddNewErrorPayload": [
      "IdentityLicenseDuplicateError",
      "IdentityLicenseError"
    ],
    "IdentityUserLicenseDeleteErrorPayload": [
      "IdentityLicenseDeleteError",
      "IdentityLicenseIsAssociatedError",
      "IdentityLicenseNotFoundError",
      "IdentityUserNotFoundError"
    ],
    "IdentityUserLicenseUpdateErrorPayload": [
      "IdentityLicenseDuplicateError",
      "IdentityLicenseError",
      "IdentityLicenseExpiredError",
      "IdentityLicenseIsAssociatedError",
      "IdentityLicenseNotFoundError"
    ],
    "IdentityUserPayload": [
      "IdentityUser",
      "IdentityUserError",
      "IdentityUserNotFoundError"
    ],
    "IndentityUserExternalServicesFollowUpBossProvisionsPayload": [
      "IdentityUserError",
      "IdentityUserExternalServiceProvisionFollowUpBossResponse"
    ],
    "Interaction": [
      "ClientGroup",
      "Lease",
      "Listing",
      "Offer",
      "Referral",
      "UserTemplate"
    ],
    "InteractionContact": [
      "InteractionContactAgent",
      "InteractionContactOther"
    ],
    "InteractionContactBase": [
      "InteractionContactAgent",
      "InteractionContactOther"
    ],
    "MarqUserDisablePayload": [
      "IdentityUserError",
      "MarqUserDisableResponse"
    ],
    "MutationPayload": [
      "AddDocumentsForUploadToInteractionPayload",
      "AddDocumentsToInteractionPayload",
      "AddImagesForUploadPayload",
      "AddInputPayload",
      "AdjustmentMutationPayload",
      "AttachmentDeleteResponse",
      "AttachmentUploadToInteractionResponse",
      "CoListMutationPayload",
      "CreateEnvelopeMutationPayload",
      "CreateOfferFromUploadedPayload",
      "DelegateGroupPayload",
      "DeleteChecklistTemplatePayload",
      "DeleteDocumentInputPayload",
      "DeleteDocumentPayload",
      "DeleteDocumentsByStatusPayload",
      "DeleteFileMutationPayload",
      "DeleteInteractionEnvelopePayload",
      "DownloadDocumentsMutationPayload",
      "DownloadEnvelopeMutationPayload",
      "DownloadPreviewDocumentMutationPayload",
      "DuplicateDocumentPayload",
      "DuplicateInteractionEnvelopePayload",
      "FinalizeTransactionPayload",
      "ForwardDocumentByIdMutationPayload",
      "ForwardDocumentsMutationPayload",
      "ForwardEnvelopeMutationPayload",
      "GenerateFileUploadUrlsPayload",
      "GenerateOfferMutationPayload",
      "IdentityCreateAddressPayload",
      "IdentityUpdateUserSignaturePayload",
      "InstallmentMutationPayload",
      "LastViewedMutationPayload",
      "ListingPriceHistoryPayload",
      "MlsMutationPayload",
      "OfferOnListingContractUploadPayload",
      "OutsideBrokerageCreatePayload",
      "OutsideBrokerageUpdatePayload",
      "PaymentTeamMemberPayload",
      "QuickLinksMutationPayload",
      "RegenerateStatementsPayload",
      "RevertInteractionEnvelopePayload",
      "SendReminderInteractionEnvelopePayload",
      "SettlementCompanyUpdatePayload",
      "SettlementContactMutationPayload",
      "SettlementContactUpdatePayload",
      "TeamUpdatePayload",
      "TransactionMutationPayload",
      "UpdateAnswerPayload",
      "UpdateDocumentInputPayload",
      "UpdateDocumentStatusPayload",
      "UpdateDocumentsEnvelopeOrderPayload",
      "UpdateDocumentsStatusPayload",
      "UpdateInteractionCoverPhotoPayload",
      "VoidInteractionEnvelopePayload"
    ],
    "MutationProblem": [
      "GenericProblem",
      "IdentityCreateAddressError",
      "IdentitySignatureError"
    ],
    "NeedsAttentionItem": [
      "BuyerAgreementsAttentionItem",
      "CommissionFormAttentionItem",
      "ComplianceReviewAttentionItem",
      "EnvelopesAttentionItem",
      "ListingAgreementsAttentionItem"
    ],
    "Node": [
      "Adjustment",
      "AdminTransaction",
      "ChecklistItemTemplateV2",
      "ChecklistItemTemplateVersionV2",
      "ChecklistTemplateDraft",
      "ChecklistTemplateV2",
      "ChecklistTemplateVersionV2",
      "ClientGroup",
      "Commission",
      "CommissionAllocation",
      "CommissionNotification",
      "CommissionNotificationRecipient",
      "CommissionTeam",
      "CommissionTeamMember",
      "CommunityListing",
      "DelegateGroup",
      "DelegateGroupHistory",
      "DelegateGroupPermission",
      "DelegateGroupRelation",
      "History",
      "IdentityAddress",
      "IdentityBroker",
      "IdentityBrokerage",
      "IdentityContact",
      "IdentityLicense",
      "IdentityLogin",
      "IdentityTeam",
      "IdentityTeamLocation",
      "IdentityTeamMember",
      "IdentityUser",
      "InteractionDocument",
      "InteractionEnvelope",
      "InteractionInfo",
      "Lease",
      "Listing",
      "Offer",
      "OutsideBrokerage",
      "PartyPermission",
      "PaymentTeamMember",
      "Referral",
      "SettlementCompany",
      "SettlementContactV2",
      "SettlementContactV2Commission",
      "TeamMemberHistory",
      "TemplateCategoryV2",
      "TemplateRuleV2",
      "UserTemplate"
    ],
    "NodeErrorPayload": [
      "ChecklistItemTemplateListV2Error",
      "ChecklistItemTemplateV2Error",
      "ChecklistItemTemplateVersionV2Error",
      "ChecklistTemplateListV2Error",
      "ChecklistTemplateV2Error",
      "ChecklistTemplateVersionV2Error",
      "ChecklistTemplatesDraftsV2Error",
      "FeatureFlagEvaluationError",
      "TemplateCategoryListV2Error",
      "TemplateCategoryV2Error",
      "TemplateRuleV2Error"
    ],
    "NodeResult": [
      "ChecklistItemTemplateV2Result",
      "ChecklistItemTemplateVersionV2Result",
      "ChecklistTemplateV2Result",
      "ChecklistTemplateVersionV2Result",
      "DeleteChecklistTemplateVersionV2Result",
      "TemplateCategoryV2Result",
      "TemplateRuleV2Result"
    ],
    "PaginationResponse": [
      "AdminTransactionsPaginationResult",
      "CommissionNotificationsPaginationResult",
      "CommissionsPaginationResult",
      "DelegateGroupHistoryPaginationResult",
      "DelegateGroupPaginationResult",
      "HistoryPaginationResult",
      "InteractionsPaginationResult",
      "ListingsPaginationResult",
      "OffersPaginationResult",
      "OutsideBrokeragePaginationResult",
      "SettlementCompaniesPaginationResult",
      "SettlementContactsPaginationResult",
      "TeamMemberHistoryPaginationResult",
      "TeamMembersV2PaginationResult",
      "TeamsV2Response"
    ],
    "RetryableErrorPayload": [
      "IdentityAddTeamMemberContactError",
      "IdentityAddressError",
      "IdentityAssociatedTeamMembersError",
      "IdentityBrokerageError",
      "IdentityContactError",
      "IdentityLicenseDeleteError",
      "IdentityLicenseError",
      "IdentityMeError",
      "IdentityTeamError",
      "IdentityTeamMemberDeleteError",
      "IdentityTeamMemberError",
      "IdentityTeamMemberUpdateError",
      "IdentityTeamMembersError",
      "IdentityTeamSupervisingBrokerError",
      "IdentityUpdateTeamMemberContactError",
      "IdentityUpdateTeamMemberPreferencesError",
      "IdentityUserContactDeleteError",
      "IdentityUserError",
      "IdentityUserTeamMembersError",
      "LobAutocompleteError"
    ],
    "RunnerResponse": [
      "CreateRunnerResponseData",
      "DownloadRunnerResponseData"
    ],
    "SidebarItem": [
      "SidebarLink"
    ],
    "TransactionTeamInterface": [
      "TransactionParentTeam",
      "TransactionTeam"
    ],
    "UpdateIdentityTeamFollowUpBossExternalServicePayload": [
      "IdentityTeamError",
      "IdentityTeamFollowUpBossInstance"
    ],
    "UpdateIdentityUserPayload": [
      "IdentityUser",
      "IdentityUserError",
      "IdentityUserNotFoundError"
    ]
  }
};
      export default result;
    